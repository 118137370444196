exports.colors = {
  black: '#2e272c',
  gray200: '#C5C5C5',
  gray300: '#7F7F7F',
  gray400: '#5E5E5E',
  gray500: '#474747',
  blue400: '#2979FF',
  blueMain: '#3626A7',
  blueLight: '#2196F3',
  red: '#FF331F',
  white: '#FBFBFF',
  offWhite: '#f0f0f0',
  doordash: '#EB1700',
  doordash_red: '#FF3008',
  doordash_gray: '#777677',
  doordash_black: '#191919',
  doordash_lightred: '#FFF0EE',
  instacart: '#0BAD0A',
  green100: '#A9ECC8',
  green200: '#5EE49D',
  green300: '#21C76F',
  green: '#018E42',
  yellow: '#FFBA08',
  orange: '#ff9800',
  pewter: '#93B7BE',
  loading: '#c9c9c994',
  splashDark: '#141414',
  splashGreen: '#3BF76A',
}
