import { useEffect, useState } from 'react'
import { checkCredentials } from '../../hooks/checkCredentials'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { colors } from '../../colors'
import 'typeface-roboto'
import { constants } from '../../constants'
import { NeuDiv, NeuButton } from 'neumorphism-react'
import { Button, InputAdornment, TextField } from '@mui/material'
import { uploadPlatformLogin } from '../../hooks/uploadPlatformLogin'
import { createLiveRequest } from '../../hooks/createLiveRequest'
import { getLiveRequest } from '../../hooks/getLiveRequest'
import AuthCode from 'react-auth-code-input'
import { respondIntermediateRequest } from '../../hooks/respondIntermediateRequest'
import { Text } from '../../components/Text'
import { removeLiveRequest } from '../../hooks/removeLiveRequest'
import { ProgressLinear } from 'ui-neumorphism'

export const DoorDashSetup = ({
  global,
  sessionId,
  currentLicense,
  maintenance,
}) => {
  const { saveSessionId, setIsLoading } = global
  const [hasLoginInfo, setHasLoginInfo] = useState()
  const [hasValidCredentials, setHasValidCredentials] = useState()
  const [emailLoginInput, setEmailLoginInput] = useState()
  const [passwordLoginInput, setPasswordLoginInput] = useState()
  const [liveRequestId, setLiveRequestId] = useState()
  const [intermediateRequest, setIntermediateRequest] = useState()
  const [liveRequest, setLiveRequest] = useState()
  const [dataRefreshed, setDataRefreshed] = useState(0)
  const [secondAuthCode, setSecondAuthCode] = useState()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [secondAuthSecondsLeft, setSecondAuthSecondsLeft] = useState(undefined)
  const [secondAuthStartTime, setSecondAuthStartTime] = useState(undefined)
  const [progressValue, setProgressValue] = useState(0)
  const [progressMessage, setProgressMessage] = useState('')

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const dateStringToTime = (dateString) => {
    return new Date(dateString.replace(' ', 'T')).getTime()
  }

  const handleCheckCredentials = async () => {
    setIsLoading(true)
    const res = await checkCredentials(sessionId, 'doordash')
    setIsLoading(false)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      saveSessionId('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (res.statusCode === 200) {
      setHasValidCredentials(res.body.tokens)
      setHasLoginInfo(res.body.login)
    } else {
      alert('Error validating account credentials.')
    }
  }

  if (hasLoginInfo && hasValidCredentials) {
    navigate({ pathname: '/doordash', search: searchParams.toString() })
  }

  useEffect(() => {
    async function temp() {
      if (!!sessionId && (hasLoginInfo === undefined || hasValidCredentials)) {
        setIsLoading(true)
        await handleCheckCredentials()
        setIsLoading(false)
      }
    }
    temp()
  })

  useEffect(() => {
    if (!!sessionId && liveRequest === undefined && hasLoginInfo) {
      handleGetLiveRequest()
    }
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      setDataRefreshed(dataRefreshed + 1)
      if (!!liveRequestId && !!hasLoginInfo) {
        const res = await getLiveRequest(sessionId, liveRequestId)
        if (res.statusCode !== 200) {
          setLiveRequestId(undefined)
        } else {
          setLiveRequest(res.body)
          setProgressValue(res.body.progress_value)
          setProgressMessage(res.body.progress_message)
          if (!!res.body.intermediate_request) {
            setIntermediateRequest(res.body.intermediate_request)
            if (secondAuthStartTime === undefined) {
              setSecondAuthStartTime(new Date().getTime())
            }
          }
          //Check for updates here
        }
      }
    }, 5000)
    return () => clearInterval(intervalId)
  }, [dataRefreshed])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!!secondAuthStartTime) {
        if (secondAuthSecondsLeft <= 0) return
        setSecondAuthSecondsLeft(
          Math.floor(110 - (new Date().getTime() - secondAuthStartTime) / 1000),
        )
      }
    }, 1000)
    return () => clearTimeout(timer)
  })

  const handleGetLiveRequest = async (liveRequestId = undefined) => {
    const res = await getLiveRequest(sessionId, liveRequestId)
    if (res.statusCode === 200) {
      setLiveRequest(res.body)
      setLiveRequestId(res.body.request_id)
      setProgressValue(res.body.progress_value)
      setProgressMessage(res.body.progress_message)
      if (!!res.body.intermediate_request) {
        setIntermediateRequest(res.body.intermediate_request)
        if (secondAuthStartTime === undefined) {
          setSecondAuthStartTime(new Date().getTime())
        }
      }
    } else {
      return //ERROR HANDLE HERE (this includes not found case)
    }
  }
  const handleEmailInputChange = (event) => {
    setEmailLoginInput(event.target.value)
  }
  const handlePasswordInputChange = (event) => {
    setPasswordLoginInput(event.target.value)
  }
  const handleSigninClick = async () => {
    if (!!emailLoginInput && !!passwordLoginInput) {
      const loginInfo = {
        email: emailLoginInput,
        password: passwordLoginInput,
      }
      setIsLoading(true)
      const res = await uploadPlatformLogin(sessionId, 'doordash', loginInfo)
      await handleCheckCredentials()
      setIsLoading(false)
      //TODO: Add error handling here
    }
  }
  const handleCreateLiveRequest = async () => {
    if (!!liveRequestId) {
      return
    }
    setIsLoading(true)

    const tempRes = await getLiveRequest(sessionId)
    if (tempRes.statusCode === 200) {
      if (
        tempRes.body.status === 'failed' ||
        tempRes.body.status === 'completed'
      ) {
        await removeLiveRequest(tempRes.body.request_id)
      } else {
        setLiveRequest(tempRes.body)
        setLiveRequestId(tempRes.body.request_id)
        setProgressValue(tempRes.body.progress_value)
        setProgressMessage(tempRes.body.progress_message)
        if (!!tempRes.body.intermediate_request) {
          setIntermediateRequest(tempRes.body.intermediate_request)
          if (secondAuthStartTime === undefined) {
            setSecondAuthStartTime(new Date().getTime())
          }
        }
      }
      setIsLoading(false)
      return
    }

    const checkCredentialsRes = await checkCredentials(sessionId, 'doordash')

    if (checkCredentialsRes.statusCode !== 200) {
      setIsLoading(false)
      window.location.reload(true)
      return //TODO ERROR HANDLING
    }
    if (!checkCredentialsRes.body.login) {
      setIsLoading(false)
      window.location.reload(true)
      return //Missing login creds (shouldnt happen)
    }
    const createRequestRes = await createLiveRequest(
      sessionId,
      'doordash',
      'get_credentials',
    )
    if (createRequestRes.statusCode === 200) {
      setLiveRequestId(createRequestRes.body.request_id)
    }
    setIsLoading(false)
  }
  const handleSendSecondAuthCode = async () => {
    if (secondAuthCode?.length !== 6) {
      return
    }
    const intermediateResponseData = {
      code: secondAuthCode,
    }
    setIsLoading(true)
    const res = await respondIntermediateRequest(
      sessionId,
      liveRequestId,
      intermediateResponseData,
    )
    if (res.statusCode !== 200) {
      console.log('Handle error here:', res) //ERROR HANDLING HERE
    } else {
      await handleGetLiveRequest(liveRequestId)
    }
    setIsLoading(false)
  }
  const handleSecondAuthInputChange = (res) => {
    setSecondAuthCode(res)
  }
  const translateRequestStatusTitle = (rawStatus) => {
    const translateDict = {
      requesting: 'Pending',
      active: 'Active',
      completed: 'Completed',
      failed: 'Failed',
    }
    return translateDict[rawStatus]
  }
  const translateRequestStatusBody = (rawStatus) => {
    const translateDict = {
      requesting: 'Your request will be fulfilled as soon as possible.',
      active:
        'Your request is being completed. Please prepare to enter a 2FA code.',
      completed: 'Your request has been completed. Click below to get started.',
      failed: 'Your request has failed.', //Add failure message here
    }
    return translateDict[rawStatus]
  }
  const translateRequestFailureMessage = (rawError) => {
    const translateDict = {
      'Incorrect login info':
        'Incorrect DoorDash login info. Please click retry.',
      'Incorrect 2FA code': 'Incorrect 2FA code. Please click retry.',
      '2FA request timed out': '2FA code request expired. Please click retry.',
      'Unknown error':
        'Something went wrong, please contact @AvalonApp or @BatchFinderDev on Telegram',
    }
    return translateDict[rawError]
  }
  const statusToColor = (status) => {
    switch (status) {
      case 'requesting':
        return colors.black
      case 'active':
        return colors.green
      case 'failed':
        return colors.red
      default:
        return colors.black
    }
  }
  const handleFinishSuccess = async () => {
    if (!!liveRequestId) {
      setIsLoading(true)
      await removeLiveRequest(liveRequestId)
      setIsLoading(false)
    }
    navigate({ pathname: '/doordash', search: searchParams.toString() })
  }
  const handleFinishFail = async () => {
    if (!!liveRequestId) {
      setIsLoading(true)
      await removeLiveRequest(liveRequestId)
      setIsLoading(false)
    }
    window.location.reload()
  }

  return (
    <div class="noselect">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', //'flex-start',
        }}
      >
        <h1>DoorDash Setup</h1>
        {!hasLoginInfo && (
          <NeuDiv
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: constants.fonts.doordash,
              maxWidth: '90vw',
              paddingBottom: '20px',
            }}
            color={colors.offWhite}
          >
            <h3
              style={{
                marginBottom: '5px',
                color: colors.doordash_black,
              }}
            >
              Sign In
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <h4
                style={{
                  color: colors.doordash_gray,
                  fontSize: '12px',
                  fontWeight: 500,
                  marginTop: '0px',
                }}
              >
                New to DoorDash?&nbsp;
              </h4>
              <h4
                style={{
                  color: colors.doordash,
                  fontSize: '12px',
                  fontWeight: 700,
                  marginTop: '0px',
                }}
              >
                Sign Up
              </h4>
            </div>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              sx={{
                paddingBottom: '5px',
              }}
            >
              <h3
                style={{
                  fontSize: '12px',
                  color: colors.doordash_black,
                  fontWeight: 700,
                  marginBottom: '5px',
                }}
              >
                Email
              </h3>
              <TextField
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    height: '10px',
                    fontSize: '14px',
                    width: '60vw',
                    marginLeft: '-5px',
                    marginRight: '5px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#e0e0e0',
                    color: colors.black,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {},
                }}
                onChange={handleEmailInputChange}
              />
              <h3
                style={{
                  fontSize: '12px',
                  color: colors.doordash_black,
                  fontWeight: 700,
                  marginBottom: '5px',
                }}
              >
                Password
              </h3>
              <TextField
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    height: '10px',
                    fontSize: '14px',
                    width: '51.5vw',
                    marginLeft: '-5px',
                    marginRight: '5px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#e0e0e0',
                  },
                }}
                onChange={handlePasswordInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        paddingLeft: '3px',
                        marginRight: '-8px',
                      }}
                    >
                      <h3
                        style={{
                          fontSize: '12px',
                          color: colors.doordash_black,
                          fontWeight: 700,
                          marginBottom: '0px',
                          marginTop: '0px',
                          zIndex: '10',
                        }}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        Show
                      </h3>
                    </InputAdornment>
                  ),
                }}
                type={passwordVisible ? 'text' : 'password'}
              />
              <a
                style={{
                  fontSize: '11px',
                  color: colors.doordash_gray,
                  fontWeight: 500,
                  marginBottom: '4px',
                  marginTop: '7px',
                  textDecoration: 'none',
                }}
                href={'https://www.doordash.com/accounts/password/reset/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Forgot your password?
              </a>
            </Box>
            {/* <button
            style={{
              backgroundColor: '#ffe4e0',
              borderColor: 'transparent',
              borderRadius: '18px',
              color: colors.doordash,
              fontWeight: 700,
              width: '80%',
              height: '35px',
              marginBottom: '8px',
            }}
          >
            Become a Dasher
          </button> */}
            <button
              style={{
                backgroundColor: colors.doordash,
                borderColor: 'transparent',
                borderRadius: '18px',
                color: colors.white,
                fontWeight: 700,
                width: '78%',
                height: '35px',
              }}
              onClick={handleSigninClick}
            >
              Sign In
            </button>
            <Box
              display="flex"
              paddingLeft="12%"
              paddingRight="9%"
              paddingTop="10px"
            >
              <p
                style={{
                  color: colors.black,
                  fontSize: '12px',
                }}
              >
                Your login info is encrypted and <b>only</b> used for the Avalon
                tool. It will <b>never</b> be shared or exposed.
              </p>
            </Box>
          </NeuDiv>
        )}
        {hasLoginInfo && !hasValidCredentials && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: constants.fonts.doordash,
              width: '100vw',
              minHeight: '50%',
              paddingBottom: '20px',
            }}
          >
            {!maintenance && !liveRequestId && (
              <NeuButton
                width="80vw"
                style={{
                  marginTop: '20px',
                  height: '40px',
                }}
                color={colors.offWhite}
                onClick={handleCreateLiveRequest}
              >
                Click to Retrieve Credentials
              </NeuButton>
            )}
            {maintenance && (
              <h2
                style={{
                  color: colors.blueMain,
                  fontSize: '20px',
                  margin: '30px 20px',
                }}
              >
                Maintenance is being carried out right now. DoorDash setup will
                be available in the next 1-2 hours. I apologize for the
                inconvenience.
              </h2>
            )}
            {!!liveRequestId && (
              <>
                <NeuDiv
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontFamily: constants.fonts.doordash,
                    width: '90%',
                    paddingBottom: '10px',
                    marginTop: '20px',
                  }}
                  color={colors.offWhite}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <h2
                      style={{
                        color: colors.black,
                        fontSize: '20px',
                        marginBottom: '15px',
                        marginTop: '10px',
                      }}
                    >
                      Request Status:
                    </h2>
                    <h2
                      style={{
                        fontSize: '20px',
                        marginBottom: '5px',
                        color: statusToColor(liveRequest?.status),
                        paddingLeft: '8px',
                        marginTop: '10px',
                      }}
                    >
                      {translateRequestStatusTitle(liveRequest?.status)}
                    </h2>
                  </Box>
                  {liveRequest?.status === 'requesting' && (
                    <p
                      style={{
                        color: colors.black,
                        fontSize: '16px',
                        paddingLeft: '20px',
                        marginRight: '8px',
                        marginTop: '5px',
                      }}
                    >
                      <b>Estimated Wait Time: </b>1 minute
                      {/* TODO: GET ACTUAL WAIT TIME */}
                    </p>
                  )}
                  {liveRequest?.status === 'active' && (
                    <>
                      <ProgressLinear
                        value={progressValue}
                        color="var(--primary)"
                        style={{
                          backgroundColor: colors.offWhite,
                          boxShadow:
                            'inset 2px 2px 3px #b5b5b5, inset -2px -2px 1px #ffffff',
                        }}
                      />
                      <p
                        style={{
                          color: colors.black,
                          fontSize: '14px',
                          paddingLeft: '20px',
                          marginRight: '8px',
                          marginTop: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        {progressMessage}
                      </p>
                    </>
                  )}
                  <p
                    style={{
                      color: colors.black,
                      fontSize: '16px',
                      paddingLeft: '20px',
                      marginRight: '8px',
                      marginTop: '5px',
                    }}
                  >
                    {translateRequestStatusBody(liveRequest?.status)}
                  </p>
                  {liveRequest?.status === 'failed' && (
                    <p
                      style={{
                        color: colors.red,
                        fontSize: '16px',
                        paddingLeft: '20px',
                        marginRight: '8px',
                        marginTop: '5px',
                        fontWeight: 500,
                      }}
                    >
                      {translateRequestFailureMessage(
                        liveRequest.client_error_message,
                      )}
                    </p>
                  )}
                </NeuDiv>{' '}
                {liveRequest?.status === 'failed' && (
                  <NeuButton
                    width="60%"
                    height="40px"
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      fontWeight: 600,
                      color: colors.black,
                    }}
                    color={colors.offWhite}
                    onClick={handleFinishFail}
                  >
                    Click to Retry
                  </NeuButton>
                )}
                {liveRequest?.status === 'completed' && (
                  <NeuButton
                    width="60%"
                    height="40px"
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      fontWeight: 600,
                      color: colors.black,
                    }}
                    color={colors.offWhite}
                    onClick={handleFinishSuccess}
                  >
                    Click to Finish
                  </NeuButton>
                )}
              </>
            )}
            {liveRequest?.status === 'active' &&
              intermediateRequest?.status === 'requesting' && (
                <NeuDiv
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontFamily: constants.fonts.doordash,
                    maxWidth: '90%',
                    paddingBottom: '30px',
                    paddingTop: '10px',
                    marginTop: '20px',
                  }}
                  color={colors.offWhite}
                >
                  <h2
                    style={{
                      textAlign: 'center',
                      fontWeight: 400,
                      fontSize: '20px',
                      marginTop: '10px',
                      marginBottom: '0px',
                      color: colors.black,
                    }}
                  >
                    Enter Verification Code
                  </h2>
                  <p
                    style={{
                      textAlign: 'left',
                      fontWeight: 400,
                      fontSize: '14px',
                      marginTop: '10px',
                      color: colors.black,
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                  >
                    You should receive a verification code from DoorDash. Enter
                    it below so the Avalon tool can continue with the setup
                    process.
                  </p>
                  <AuthCode
                    allowedCharacters="numeric"
                    onChange={handleSecondAuthInputChange}
                    inputClassName="input"
                  />
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: '14px',
                      marginTop: '20px',
                      color: colors.black,
                    }}
                  >
                    Expires in: {secondAuthSecondsLeft} seconds
                  </p>
                  {secondAuthCode?.length === 6 && (
                    <NeuButton
                      width="40%"
                      height="40px"
                      style={{
                        marginTop: '20px',
                        fontSize: '20px',
                      }}
                      color={colors.offWhite}
                      onClick={handleSendSecondAuthCode}
                      disabled={secondAuthCode?.length !== 6}
                    >
                      Submit
                    </NeuButton>
                  )}
                </NeuDiv>
              )}
            {liveRequest?.status === 'active' &&
              intermediateRequest?.status === 'completed' && (
                <NeuDiv
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontFamily: constants.fonts.doordash,
                    maxWidth: '90%',
                    marginTop: '20px',
                  }}
                  color={colors.offWhite}
                >
                  <h2
                    style={{
                      textAlign: 'center',
                      fontWeight: 400,
                      fontSize: '20px',
                      color: colors.green,
                      fontWeight: '500',
                      margin: '10px',
                    }}
                  >
                    Thank you for entering the code
                  </h2>
                </NeuDiv>
              )}
          </Box>
        )}
      </Box>
    </div>
  )
}
