import { NeuButton } from 'neumorphism-react'
import { colors } from '../../../colors'

export const BatchFinderMenuButton = ({
  label,
  icon,
  fontSize,
  disabled,
  subLabel,
  onClick,
}) => {
  return (
    <div
      class="noselect"
      style={{
        display: 'block',
        marginBottom: '25px',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <NeuButton
        style={{
          width: '70vw',
          height: '50px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: colors.black,
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
          textDecoration: disabled ? 'line-through' : 'none',
        }}
        color={colors.offWhite}
        distance={2}
        onClick={onClick}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '0px 15px',
            alignItems: 'center',
          }}
        >
          {icon}
          <div
            style={{
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: colors.black,
                fontSize: !!fontSize ? fontSize : 'auto',
              }}
            >
              {label}
            </p>
            {!!subLabel && (
              <p
                style={{
                  color: colors.gray300,
                  fontSize: '13px',
                  fontWeight: 500,
                }}
              >
                {subLabel}
              </p>
            )}
          </div>
        </div>
      </NeuButton>
    </div>
  )
}
