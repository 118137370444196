import { DriveEtaRounded, ShoppingBasketRounded } from '@mui/icons-material'
import { colors } from '../../../colors'
import './batchCard.css'

export const AcceptedBatchInfoCard = ({ batch }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        boxShadow: '#039B92 0 15px 40px -5px',
        borderRadius: '21px',
      }}
      class="activeBatchCard"
    >
      <div
        style={{
          position: 'absolute',
          padding: '15px',
          width: '90%',
          height: '182px',
          zIndex: 1,
          color: '#01CC8E',
          opacity: 0.3,
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        {[...Array(7)].map(() => (
          <p>
            www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip&nbsp;&nbsp;&nbsp;www.avalon.rip
          </p>
        ))}
      </div>
      <div
        style={{
          padding: '20px 15px 20px 15px',
          backdropFilter: 'blur(20px)',
          '-webkit-backdrop-filter': 'blur(20px)',
          borderRadius: '21px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: '20px',
          }}
        >
          <p
            style={{
              color: colors.white,
              fontSize: '32px',
              fontWeight: 600,
              paddingBottom: '5px',
            }}
          >
            ${batch.earnings}
          </p>
          <p
            style={{
              color: colors.white,
              fontSize: '22px',
              fontWeight: 600,
              paddingBottom: '5px',
            }}
          >
            ${batch.tip} Tip
          </p>
        </div>

        <p
          style={{
            color: colors.white,
            fontSize: '20px',
            fontWeight: 600,
            paddingBottom: '12px',
          }}
        >
          {`${batch.customers} ${
            batch.customers === 1 ? 'Customer' : 'Customers'
          }`}
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <DriveEtaRounded sx={{ color: colors.white }} />
            <p
              style={{
                fontSize: '18px',
                color: colors.white,
                paddingLeft: '5px',
              }}
            >
              {batch.distance} mi
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ShoppingBasketRounded sx={{ color: colors.white }} />
            <p
              style={{
                fontSize: '18px',
                color: colors.white,
                paddingLeft: '5px',
                paddingTop: '3px',
              }}
            >
              {batch.items} Items ({batch.units} Units)
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
