import { Button } from '@mui/material'
import { NeuDiv } from 'neumorphism-react'
import { useEffect, useState } from 'react'
import { Card, Dialog } from 'ui-neumorphism'
import { colors } from '../../../colors'

export const FakeLocationSelector = ({
  visible,
  hideFakeGPS,
  storeLocations,
  realLatitude,
  realLongitude,
  handleSaveLocation,
  usingRealLocation,
  currentSettingLatitude,
  currentSettingLongitude,
  currentSettingLocationName,
}) => {
  const [tempSelection, setTempSelection] = useState()
  const [internalUsingRealLocation, _internalUsingRealLocation] =
    useState(usingRealLocation)

  const demandColor = (rank) => {
    if (rank === 1) {
      return '#00e9f5'
    } else if (rank === 2) {
      return '#4caf50'
    } else if (rank === 3) {
      return '#ffdd00'
    } else if (rank === 4) {
      return '#ff9800'
    } else {
      return '#ef5350'
    }
  }
  const calculateDistance = (latitude1, longitude1, latitude2, longitude2) => {
    let theta = longitude1 - longitude2
    let distance =
      60 *
      1.1515 *
      (180 / Math.PI) *
      Math.acos(
        Math.sin(latitude1 * (Math.PI / 180)) *
          Math.sin(latitude2 * (Math.PI / 180)) +
          Math.cos(latitude1 * (Math.PI / 180)) *
            Math.cos(latitude2 * (Math.PI / 180)) *
            Math.cos(theta * (Math.PI / 180)),
      )
    return distance
  }

  const handleSaveAndClose = () => {
    if (!usingRealLocation && internalUsingRealLocation) {
      handleSaveLocation(realLatitude, realLongitude, true, 'n/a')
    } else if (tempSelection) {
      handleSaveLocation(
        tempSelection.latitude,
        tempSelection.longitude,
        false,
        tempSelection.name,
      )
    }
    hideFakeGPS()
  }

  const openInGoogleMaps = () => {
    let latitude
    let longitude
    if (internalUsingRealLocation) {
      latitude = realLatitude
      longitude = realLongitude
    } else if (tempSelection) {
      latitude = tempSelection.latitude
      longitude = tempSelection.longitude
    } else {
      latitude = currentSettingLatitude
      longitude = currentSettingLongitude
    }
    window.open(`https://maps.google.com?q=${latitude},${longitude}`)
  }

  const sortedStoreLocations = () => {
    const rawStores = JSON.parse(JSON.stringify(storeLocations))
    for (let store of rawStores) {
      store.distanceToUser = calculateDistance(
        store.latitude,
        store.longitude,
        realLatitude,
        realLongitude,
      )
    }
    rawStores.sort((a, b) => a.distanceToUser - b.distanceToUser)
    rawStores.sort((a, b) => a.rank - b.rank)
    return rawStores
  }

  return (
    <Dialog minWidth={350} visible={visible} onClose={hideFakeGPS}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '0px 0px 10px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px 10px 0px 10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80vw',
              paddingBottom: '10px',
            }}
          >
            <p
              style={{
                fontSize: '28px',
                color: colors.black,
                fontWeight: 500,
                paddingBottom: '10px',
                textAlign: 'center',
              }}
            >
              Location Options
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '80vw',
                justifyContent: 'center',
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  backgroundColor: internalUsingRealLocation
                    ? '#1976D2'
                    : colors.offWhite,
                  width: '40%',
                  height: '35px',
                  textAlign: 'center',
                  borderRadius: '15px 0px 0px 15px',
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: '#1976D2',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setTempSelection()
                  _internalUsingRealLocation(true)
                }}
              >
                <p
                  style={{
                    color: internalUsingRealLocation ? 'white' : colors.gray500,
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Real Location
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: !internalUsingRealLocation
                    ? '#1976D2'
                    : colors.offWhite,
                  width: '40%',
                  height: '35px',
                  textAlign: 'center',
                  borderRadius: '0px 15px 15px 0px',
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: '#1976D2',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setTempSelection()
                  _internalUsingRealLocation(false)
                }}
              >
                <p
                  style={{
                    color: !internalUsingRealLocation
                      ? 'white'
                      : colors.gray500,
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Fake Location
                </p>
              </div>
            </div>
            <NeuDiv
              color={colors.offWhite}
              revert
              distance={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '10px',
                color: 'black',
                padding: '10px 10px',
                alignItems: 'center',
                fontSize: '1em',
              }}
            >
              {internalUsingRealLocation && (
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '-5px',
                    paddingBottom: '0px',
                  }}
                >
                  <b>Status: </b>BatchFinder will use your current location to
                  find available batches.
                </p>
              )}
              {!tempSelection &&
                !internalUsingRealLocation &&
                !usingRealLocation &&
                currentSettingLatitude && (
                  <p
                    style={{
                      textAlign: 'center',
                      marginBottom: '-5px',
                      paddingBottom: '5px',
                    }}
                  >
                    <b>Status: </b>BatchFinder has set your location to{' '}
                    <b>{currentSettingLocationName}</b>,{' '}
                    <b>
                      {calculateDistance(
                        currentSettingLatitude,
                        currentSettingLongitude,
                        realLatitude,
                        realLongitude,
                      ).toFixed(1)}
                    </b>{' '}
                    miles away from your location.
                  </p>
                )}
              {tempSelection && (
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '-5px',
                    paddingBottom: '0px',
                  }}
                >
                  <b>Status: </b>BatchFinder will set your location to{' '}
                  <b>{tempSelection.name}</b>,{' '}
                  <b>
                    {calculateDistance(
                      tempSelection.latitude,
                      tempSelection.longitude,
                      realLatitude,
                      realLongitude,
                    ).toFixed(1)}
                  </b>{' '}
                  miles away from your location.
                </p>
              )}
              {!internalUsingRealLocation && !tempSelection && (
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <b>Please select a location below. </b>BatchFinder will search
                  for batches in that area.
                </p>
              )}
              {!(!internalUsingRealLocation && !tempSelection) && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    width: 'auto',
                    margin: '10px 0px 2px 0px',
                    fontSize: '12px',
                  }}
                  onClick={openInGoogleMaps}
                >
                  View in Google Maps
                </Button>
              )}
            </NeuDiv>
          </div>
          {!internalUsingRealLocation && (
            <>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: '20px',
                  color: colors.black,
                  marginBottom: '10px',
                }}
              >
                Select a Store Location
              </p>
              {storeLocations && (
                <NeuDiv
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    maxHeight: '35vh',
                    overflowY: 'scroll',
                  }}
                  color={colors.offWhite}
                  distance={2}
                  revert
                >
                  {sortedStoreLocations().map((item) => (
                    <div
                      key={`${item.name}_${item.latitude}_${item.longitude}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '18px',
                        color: colors.black,
                        margin: '5px 0px',
                        borderStyle: 'solid',
                        borderColor:
                          tempSelection?.latitude === item.latitude &&
                          tempSelection?.longitude === item.longitude
                            ? colors.blue400
                            : colors.gray200,
                        borderWidth: '2px',
                        borderRadius: '8px',
                        padding: '5px 10px 5px 10px',
                        minHeight: '30px',
                      }}
                      onClick={() => {
                        if (
                          tempSelection?.latitude === item.latitude &&
                          tempSelection?.longitude === item.longitude
                        ) {
                          setTempSelection()
                        } else {
                          setTempSelection(item)
                          _internalUsingRealLocation(false)
                        }
                      }}
                    >
                      <div
                        style={{
                          width: '70%',
                        }}
                      >
                        <p style={{ fontWeight: 500, fontSize: '14px' }}>
                          {item.name}
                        </p>
                      </div>
                      <p>
                        {parseFloat(
                          calculateDistance(
                            item.latitude,
                            item.longitude,
                            realLatitude,
                            realLongitude,
                          ).toFixed(1),
                        )}{' '}
                        mi.
                      </p>
                      <div
                        style={{
                          borderRadius: '100%',
                          backgroundColor: demandColor(item.rank),
                          width: '15px',
                          height: '15px',
                        }}
                      />
                    </div>
                  ))}
                </NeuDiv>
              )}
            </>
          )}
          <Button
            variant="contained"
            sx={{
              width: 'auto',
              height: '30px',
              marginTop: '10px',
            }}
            onClick={handleSaveAndClose}
          >
            {(!usingRealLocation && internalUsingRealLocation) ||
            (!internalUsingRealLocation && tempSelection)
              ? 'Save & '
              : ''}
            Close
          </Button>
        </div>
      </Card>
    </Dialog>
  )
}
