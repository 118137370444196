import { NeuButton } from 'neumorphism-react'
import { Button, Card, Dialog, Tooltip } from 'ui-neumorphism'
import { colors } from '../colors'
import { Chip } from 'ui-neumorphism'
import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded'

export const AccountInfoDialog = ({
  visible,
  hideAccountInfo,
  accountId,
  openCopySnackbar,
}) => {
  const handleCopyAccountId = () => {
    navigator.clipboard.writeText(accountId)
    openCopySnackbar()
  }

  return (
    <Dialog minWidth={300} visible={visible} onClose={hideAccountInfo}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          {/* <h1
            style={{
              fontSize: '24px',
              marginBottom: '10px',
            }}
          >
            Account Info
          </h1> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <p
              style={{
                fontSize: '12px',
                color: colors.gray300,
                marginLeft: '5px',
                marginBottom: '3px',
              }}
            >
              Account ID
            </p>
            <div onClick={handleCopyAccountId}>
              <Chip
                action={
                  <ContentCopyRounded
                    sx={{
                      fontSize: '20px',
                      color: colors.blueLight,
                    }}
                  />
                }
                type="info"
                style={{
                  fontSize: '20px',
                }}
              >
                {accountId}&nbsp;
              </Chip>
            </div>
          </div>
          <NeuButton
            style={{
              width: '40vw',
              height: '30px',
              textTransform: 'none',
              fontSize: '18px',
              color: colors.black,
              marginTop: '20px',
            }}
            color={colors.offWhite}
            distance={2}
            onClick={hideAccountInfo}
          >
            CLOSE
          </NeuButton>
        </div>
      </Card>
    </Dialog>
  )
}
