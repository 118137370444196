import axios from 'axios'
import { CLIENT_API_KEY } from '../../access_keys'

export const getStoreLocations = async (
  session_id,
  latitude,
  longitude,
  max_rank = 5,
) => {
  const res = {
    body: '',
    statusCode: 0,
  }
  const config = {
    method: 'POST',
    url: 'https://owworarpe5.execute-api.us-west-1.amazonaws.com/Prod/avalon/instacart/store_locations',
    headers: {
      apikey: CLIENT_API_KEY,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ session_id, latitude, longitude, max_rank }),
  }
  try {
    const response = await axios(config)
    res.statusCode = 200
    res.body = response.data
    return res
  } catch (error) {
    res.statusCode = error.response.status
    res.body = error.response.data
    return res
  }
}
