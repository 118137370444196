import { Link, useLocation } from 'react-router-dom'
import { colors } from '../../../colors'

export const CTAButton = (props) => {
  const location = useLocation()

  return (
    <Link
      style={{
        padding: '1rem',
        fontSize: '1rem',
        fontWeight: 600,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: colors.splashGreen,
        color: colors.splashDark,
        border: 'none',
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        transition: 'transform 0.1s, filter 0.1s',
        textDecoration: 'none',
      }}
      to={`/${location.search}`}
      onTouchStart={(e) => {
        e.currentTarget.style.transform = 'scale(0.95)'
        e.currentTarget.style.filter = 'brightness(0.95)'
      }}
      onTouchEnd={(e) => {
        e.currentTarget.style.transform = 'scale(1)'
        e.currentTarget.style.filter = 'brightness(1)'
      }}
    >
      {props.children}
    </Link>
  )
}
