import PaypalTemplate from '../PaypalTemplate'

export const PurchasePremiumOneDay = ({
  config,
  isLoggedIn,
  saveSessionId,
  sessionId,
  setCurrentLicense,
  global,
}) => {
  if (!config) return
  return (
    <PaypalTemplate
      isLoggedIn={isLoggedIn}
      productDescription={'1 Day Premium License'}
      category={'1 Day Premium'}
      price={config.value}
      sessionId={sessionId}
      saveSessionId={saveSessionId}
      setCurrentLicense={setCurrentLicense}
      global={global}
    />
  )
}
