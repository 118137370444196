import { Card, Dialog } from 'ui-neumorphism'
import { colors } from '../colors'
import { Button } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useState } from 'react'

export const JoinTelegramGroupDialog = ({ sessionId, visible, hideDialog }) => {
  const [showAgain, setShowAgain] = useState(true)

  return (
    <Dialog visible={visible} onClose={() => hideDialog(showAgain)}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
          width: '90vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 10px',
            paddingBottom: '70px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: '22px',
                color: colors.black,
                paddingBottom: '15px',
                fontWeight: 600,
              }}
            >
              Join the BatchFinder Telegram!
            </p>
            <p
              style={{
                textAlign: 'left',
                fontSize: '18px',
                color: colors.black,
                whiteSpace: 'pre-line',
                margin: '0px 10px 5px 10px',
              }}
            >
              • Chat with other Shoppers
            </p>
            <p
              style={{
                textAlign: 'left',
                fontSize: '18px',
                color: colors.black,
                whiteSpace: 'pre-line',
                margin: '0px 10px 5px 10px',
              }}
            >
              • Get update announcements
            </p>
            <p
              style={{
                textAlign: 'left',
                fontSize: '18px',
                color: colors.black,
                whiteSpace: 'pre-line',
                margin: '0px 10px 5px 10px',
              }}
            >
              • Exchange tips and tricks
            </p>
          </div>
          <Button
            variant="contained"
            color="success"
            sx={{ marginTop: '20px' }}
            onClick={() =>
              window.open('https://t.me/+IOe5ap0cjYo1OTUx', '_blank')
            }
          >
            Join Group
          </Button>
          {!!sessionId && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '5px',
                alignItems: 'center',
                position: 'absolute',
                bottom: '10px',
                left: '10px',
              }}
            >
              <Checkbox
                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                onClick={(e) => setShowAgain(!e.target.checked)}
              />
              <p
                style={{
                  fontSize: '14px',
                  color: colors.black,
                }}
              >
                Don't show this again
              </p>
            </div>
          )}
          <Button
            variant="contained"
            sx={{
              marginTop: '20px',
              position: 'absolute',
              bottom: '15px',
              right: '15px',
            }}
            onClick={() => hideDialog(showAgain)}
          >
            CLOSE
          </Button>
        </div>
      </Card>
    </Dialog>
  )
}
