import { Link } from 'react-router-dom'
import { colors } from '../../colors'
import { useState } from 'react'

const PerDaySavings = ({ licenseLength, savePercent }) => {
  if (licenseLength === 1) return

  return (
    <p
      style={{
        fontWeight: 200,
        color: colors.splashDark,
        fontSize: '0.75rem',
        color: '#515151',
        margin: '2px 0px',
      }}
    >
      <b style={{ color: colors.black }}>save {savePercent}%</b> compared to 1
      day license
    </p>
  )
}

export const PremiumCard = ({
  handleGetLicensePricing,
  handleGetLicenseLink,
  pricingConfig,
}) => {
  const [licenseLength, _licenseLength] = useState(30)

  const displayPrice = handleGetLicensePricing(
    pricingConfig,
    'premium',
    licenseLength,
  )

  const one_day_cost = handleGetLicensePricing(pricingConfig, 'premium', 1)
  const savePercent = (
    ((one_day_cost * licenseLength - displayPrice) /
      (one_day_cost * licenseLength)) *
    100
  ).toFixed(0)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        padding: '0.5rem 0.5rem',
        backgroundColor: '#FBFAF8',
        borderRadius: '15px 15px 15px 15px',
        marginTop: '4rem',
      }}
    >
      <p style={{ fontSize: '4.5em', marginTop: '-4rem' }}>🔥</p>
      <h2
        style={{
          color: '#F21B3F',
          fontWeight: 800,
          paddingTop: '0.3rem',
          fontSize: '28px',
        }}
      >
        Premium
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F21B3F',
          padding: '4px',
          marginTop: '0.5rem',
        }}
      >
        <p style={{ color: '#FBFAF8', fontSize: '0.7em' }}>MOST POPULAR</p>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: '4px',
          marginTop: '1rem',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🤑</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Auto accepts batches
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔥</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Faster performance
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔀</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          30 filter profiles
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔍</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Runs in background
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔔</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Batch acceptance alerts
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>📱</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Compatible with all devices
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: '0.2rem',
        }}
      >
        {!!displayPrice && (
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                color: colors.splashDark,
                fontWeight: 700,
              }}
            >
              ${displayPrice}{' '}
            </p>
            <PerDaySavings
              licenseLength={licenseLength}
              savePercent={savePercent}
            />
          </span>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          minWidth: '250px',
          backgroundColor: '#D9D9D9',
          padding: '2px',
          borderRadius: '5px',
          marginTop: '5px',
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: licenseLength === 30 ? '#FBFAF8' : 'transparent',
            fontWeight: licenseLength === 30 ? 600 : 400,
            borderRadius: '4px',
            padding: '4px 0px',
            marginRight: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength(30)}
        >
          30 Days
        </div>
        {licenseLength === 1 && (
          <div
            style={{
              margin: '4px 0px',
              width: '1px',
              backgroundColor: '#9F9F9F',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            backgroundColor: licenseLength === 7 ? '#FBFAF8' : 'transparent',
            fontWeight: licenseLength === 7 ? 600 : 400,
            borderRadius: '4px',
            padding: '4px 0px',
            margin: '0px 1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength(7)}
        >
          7 Days
        </div>
        {licenseLength === 30 && (
          <div
            style={{
              margin: '4px 0px',
              width: '1px',
              backgroundColor: '#9F9F9F',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            backgroundColor: licenseLength === 1 ? '#FBFAF8' : 'transparent',
            fontWeight: licenseLength === 1 ? 600 : 400,
            borderRadius: '4px',
            padding: '4px 0px',
            marginLeft: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength(1)}
        >
          1 Day
        </div>
      </div>
      <Link
        to={`./${handleGetLicenseLink('premium', licenseLength)}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '10px',
          height: '3rem',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#F21B3F',
          color: 'white',
          fontSize: '20px',
          fontWeight: 900,
          marginTop: '1rem',
          textDecoration: 'none',
          transition: 'transform 0.1s, filter 0.1s',
        }}
        onTouchStart={(e) => {
          e.currentTarget.style.transform = 'scale(0.95)'
        }}
        onTouchEnd={(e) => {
          e.currentTarget.style.transform = 'scale(1)'
        }}
      >
        Purchase Now
      </Link>
    </div>
  )
}
