import { colors } from '../../colors'

export const Testimonials = () => {
  return (
    <div
      style={{
        borderRadius: '10px',
        padding: '20px',
        margin: '10px',
        backgroundColor: '#2e2e2e',
      }}
    >
      <p>
        “I make{' '}
        <span style={{ fontWeight: 'bold', color: colors.splashGreen }}>
          30%-50% more
        </span>{' '}
        because I can set high filters and prioritize my time to better batches.
        I love that we don't have to sit in the parking lot and swipe. I can be
        home or do whatever.”
      </p>
      <p
        style={{
          fontStyle: 'italic',
          textAlign: 'right',
          marginTop: '10px',
          marginRight: '10px',
        }}
      >
        - Alexa Mitchell
      </p>
    </div>
  )
}
