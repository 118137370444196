import { Button } from '@mui/material'
import { NeuButton } from 'neumorphism-react'
import { useMatch } from 'react-router-dom'
import { colors } from '../colors'
import LogoutRounded from '@mui/icons-material/LogoutRounded'

export const LogOutButton = ({ sessionId, handleLogOut }) => {
  if (sessionId !== undefined) {
    return (
      <NeuButton
        style={{
          width: '70vw',
          height: '50px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: colors.black,
        }}
        color={colors.offWhite}
        distance={2}
        onClick={handleLogOut}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '0px 15px',
          }}
        >
          <LogoutRounded sx={{ marginRight: 'auto', fontSize: 28 }} />
          <p
            style={{
              marginRight: 'auto',
              color: colors.black,
              marginTop: '2px',
            }}
          >
            Log Out
          </p>
        </div>
      </NeuButton>
    )
  }
}
