import { CloseRounded } from '@mui/icons-material'
import IosShare from '@mui/icons-material/IosShare'
import { useMatch } from 'react-router-dom'

export const PWAPromptiOS = ({ closePWAPrompt }) => {
  const onSplash = useMatch('/')
  const onBlog = useMatch('/blog/*')
  const onOnboarding = useMatch('/onboarding')
  return (
    <div
      class="noselect"
      style={{
        display: onSplash || onBlog || onOnboarding ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        animationName: 'floating',
        animationDuration: '2s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#c9daff',
          width: '90vw',
          height: 'auto',
          padding: '8px 10px 13px 15px',
          borderRadius: '8px',
        }}
      >
        <CloseRounded
          sx={{
            alignSelf: 'flex-end',
            fontSize: '20px',
          }}
          onClick={closePWAPrompt}
        />
        <p style={{ color: 'black', marginTop: '-10px' }}>
          <b>Install the Avalon app: </b>Tap{' '}
          <IosShare sx={{ color: '#007AFF', marginBottom: '-4px' }} />, scroll
          down, and then "Add to Home Screen" (Safari only)
        </p>
      </div>
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '20px solid transparent',
          borderRight: '20px solid transparent',
          borderTop: '25px solid #c9daff',
          marginBottom: '15px',
        }}
      />
    </div>
  )
}
