import { FadeUp } from './FadeUp'

export const Features = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '20px 10px',
      }}
    >
      <FadeUp>
        <div
          style={{
            borderLeft: '0.4rem solid #3BF76A',
            padding: '5px 15px 15px 15px',
          }}
        >
          <h2 style={{ marginBottom: '10px' }}>Auto-Accept Batches</h2>
          <p style={{ color: '#C2C3C5' }}>
            Accept top-paying batches before other Shoppers
          </p>
        </div>
      </FadeUp>

      <FadeUp>
        <div
          style={{
            borderLeft: '0.4rem solid #34B955',
            padding: '5px 15px 15px 15px',
          }}
        >
          <h2 style={{ marginBottom: '10px' }}>Precision Filtering</h2>
          <p style={{ color: '#C2C3C5' }}>
            Tailor your batch filters with advanced criteria like minimum tip
            and max distance
          </p>
        </div>
      </FadeUp>

      <FadeUp>
        <div
          style={{
            borderLeft: '0.4rem solid #2D8543',
            padding: '5px 15px 15px 15px',
          }}
        >
          <h2 style={{ marginBottom: '10px' }}>Background Operation</h2>
          <p style={{ color: '#C2C3C5' }}>
            Set and forget - BatchFinder runs in the background, even when your
            phone is off
          </p>
        </div>
      </FadeUp>

      <FadeUp>
        <div
          style={{
            borderLeft: '0.4rem solid #295E37',
            padding: '5px 15px 15px 15px',
          }}
        >
          <h2 style={{ marginBottom: '10px' }}>Real-Time Alerts</h2>
          <p style={{ color: '#C2C3C5' }}>
            Get notified immediately when a batch is accepted
          </p>
        </div>
      </FadeUp>
    </div>
  )
}
