import { Button } from '@mui/material'
import { NeuButton } from 'neumorphism-react'
import { useMatch } from 'react-router-dom'
import { colors } from '../colors'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

export const GlobalHomeButton = () => {
  const onSplash = useMatch('/')
  const onBatchFinderGuide = useMatch('/batchfinder/guide')
  const onBatchFinderFilters = useMatch('/batchfinder/filters')
  const onBatchFinderSetup = useMatch('/batchfinder/setup')
  const onBatchFinderApp = useMatch('/batchfinder/app')
  const onBatchFinderActive = useMatch('/batchfinder/active')
  const onReferral = useMatch('/referral')
  const onBatchFinder = useMatch('/batchfinder')
  const onTurbo = useMatch('/batchfinder/turbo')
  const onLicenseAccess = useMatch('/license')
  const onLicenseAccess1 = useMatch('/license/license_1')
  const onLicenseAccess7 = useMatch('/license/license_7')
  const onLicenseAccess30 = useMatch('/license/license_30')
  const onBatchFinderService = useMatch('/batchfinder/service')
  const onPremiumAccess1 = useMatch('/license/license_premium_1')
  const onPremiumAccess7 = useMatch('/license/license_premium_7')
  const onPremiumAccess30 = useMatch('/license/license_premium_30')
  const onTurboAccess1 = useMatch('/license/license_turbo_1')
  const onTurboAccess7 = useMatch('/license/license_turbo_7')
  const onTurboAccess30 = useMatch('/license/license_turbo_30')
  const onBatchFinderIdentity = useMatch('/batchfinder/identity')
  const onBlog = useMatch('/blog/*')
  const onApp = useMatch('/app')
  const onOnboarding = useMatch('/onboarding')
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <div
      class="noselect"
      style={{
        display:
          onSplash ||
          onApp ||
          onBatchFinderGuide ||
          onBatchFinderFilters ||
          onBatchFinderSetup ||
          onBatchFinderApp ||
          onReferral ||
          onLicenseAccess ||
          onBatchFinder ||
          onLicenseAccess1 ||
          onLicenseAccess7 ||
          onLicenseAccess30 ||
          onBatchFinderActive ||
          onBatchFinderService ||
          onPremiumAccess1 ||
          onPremiumAccess7 ||
          onPremiumAccess30 ||
          onTurboAccess1 ||
          onTurboAccess7 ||
          onTurboAccess30 ||
          onOnboarding ||
          onBlog ||
          onBatchFinderIdentity ||
          onTurbo
            ? 'none'
            : 'block',
        zIndex: 1,
      }}
    >
      <NeuButton
        style={{
          color: colors.black,
          fontWeight: 600,
          width: '25vw',
          height: '40px',
          fontSize: '18px',
          position: 'fixed',
          bottom: 0,
          left: 0,
          borderRadius: '0px 20px 0px 0px',
          padding: '5px 0px 5px 0px',
        }}
        color="#c7d9ff"
        distance={2}
        onClick={() =>
          navigate({ pathname: '/app', search: searchParams.toString() })
        }
      >
        Home
      </NeuButton>
    </div>
  )
}
