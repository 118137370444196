import { NeuDiv } from 'neumorphism-react'
import { colors } from '../../../colors'
import DriveEtaRoundedIcon from '@mui/icons-material/DriveEtaRounded'
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded'

export const BatchListItem = ({ batch, onClick }) => {
  const formattedStoreStrings = []
  for (let i = 0; i < batch.storeBrands.length; i++) {
    formattedStoreStrings.push(
      `${batch.storeBrands[i]} - ${batch.storeAddresses[i]}`,
    )
  }
  return (
    <NeuDiv
      style={{
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        marginTop: '0px',
        marginBottom: '10px',
        minHeight: `${143 + 20 * batch.storeAddresses.length}px`,
        height: `${143 + 20 * batch.storeAddresses.length}px`,
      }}
      color={colors.offWhite}
      distance={2}
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p
          style={{
            color: colors.black,
            fontSize: '30px',
            fontWeight: 'bold',
            paddingBottom: '5px',
          }}
        >
          ${batch.earnings}
        </p>
        <p
          style={{
            color: colors.black,
            fontSize: '20px',
            fontWeight: 500,
            paddingBottom: '5px',
          }}
        >
          ${batch.tip} Tip
        </p>
      </div>

      <p
        style={{
          color: colors.black,
          fontSize: '20px',
          fontWeight: 500,
          paddingBottom: '12px',
        }}
      >
        {`${batch.customers} ${
          batch.customers === 1 ? 'Customer' : 'Customers'
        }`}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DriveEtaRoundedIcon sx={{ color: colors.black }} />
          <p
            style={{
              fontSize: '18px',
              color: colors.black,
              paddingLeft: '5px',
            }}
          >
            {batch.distance} mi
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ShoppingBasketRoundedIcon sx={{ color: colors.black }} />
          <p
            style={{
              fontSize: '18px',
              color: colors.black,
              paddingLeft: '5px',
              paddingTop: '3px',
            }}
          >
            {batch.items} Items ({batch.units} Units)
          </p>
        </div>
      </div>
      {formattedStoreStrings.map((item) => (
        <p
          style={{
            fontSize: '17px',
            color: colors.black,
          }}
          key={item}
        >
          {item}
        </p>
      ))}
    </NeuDiv>
  )
}
