import { Button } from '@mui/material'
import { StartTurbo } from './StartTurbo'
import { colors } from '../../../../colors'
import { openInGoogleMaps } from '../helpers/openInGoogleMaps'
import { NeuDiv } from 'neumorphism-react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const TurboStopped = ({
  global,
  sessionId,
  accountId,
  currentLicense,
  turboRunning,
  _turboRunning,
  _locationSelectorVisible,
  locationIsReal,
  locationName,
  locationLatitude,
  locationLongitude,
  realLatitude,
  realLongitude,
  storeLocations,
  hasRetrievedServiceSettings,
  serviceStopReason,
  handleGetServiceSettings,
  _showBackgrounding,
  showBackgrounding,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    !turboRunning && (
      <NeuDiv
        style={{
          width: '95vw',
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowY: 'scroll',
          marginTop: '10px',
          justifyContent: 'space-between',
          padding: '15px 0px 30px 0px',
        }}
        color={colors.offWhite}
        distance={2}
        revert
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!locationIsReal && locationName && (
            <p
              style={{
                color: colors.black,
                textAlign: 'center',
                padding: '5px 10px 0px 10px',
                fontSize: '0.9em',
                fontWeight: 400,
              }}
            >
              Current Faked Location: {locationName}
            </p>
          )}
          {locationLatitude && locationLongitude && (
            <Button
              variant="contained"
              color="success"
              sx={{
                width: 'auto',
                margin: '10px 0px 2px 0px',
                fontSize: '14px',
              }}
              onClick={() =>
                openInGoogleMaps(locationLatitude, locationLongitude)
              }
            >
              View Selected Location
            </Button>
          )}
          {storeLocations && realLatitude && realLongitude && (
            <Button
              variant="contained"
              sx={{
                width: 'auto',
                margin: '10px 0px 2px 0px',
                fontSize: '14px',
              }}
              onClick={() => _locationSelectorVisible(true)}
            >
              Set Location
            </Button>
          )}
        </div>
        {!currentLicense && (
          <>
            <p
              style={{
                color: colors.red,
                textAlign: 'center',
                paddingTop: '10px',
                fontSize: '0.9em',
                fontWeight: 500,
              }}
            >
              License required. Trial license keys are available on the License
              page.
            </p>
            <Button
              variant="contained"
              style={{
                width: 'auto',
              }}
              onClick={() =>
                navigate({
                  pathname: '/license',
                  search: searchParams.toString(),
                })
              }
            >
              Click here to view licenses
            </Button>
          </>
        )}
        {currentLicense && !locationLatitude && hasRetrievedServiceSettings && (
          <p
            style={{
              color: colors.red,
              textAlign: 'center',
              padding: '10px 20px 0px 20px',
              fontSize: '0.9em',
              fontWeight: 400,
            }}
          >
            <b>
              Location required. Please set your location above to run
              BatchFinder.
            </b>
            <br />
            <br />
            <br />
            {storeLocations &&
              `If you don't see the "SET LOCATION" button above. Make sure
                  you have enabled Location Services for your web browser.`}
            {!storeLocations &&
              (!realLatitude || !realLongitude) &&
              'Please check if Location Services are enabled for your web browser and specifically for this website. Your location is required to retrieve nearby stores.'}
            {!storeLocations &&
              realLatitude &&
              realLongitude &&
              'Retrieving nearby store locations...'}
          </p>
        )}
        <StartTurbo
          global={global}
          sessionId={sessionId}
          accountId={accountId}
          _turboRunning={_turboRunning}
          currentLicense={currentLicense}
          locationLatitude={locationLatitude}
          locationLongitude={locationLongitude}
          handleGetServiceSettings={handleGetServiceSettings}
          showBackgrounding={showBackgrounding}
          _showBackgrounding={_showBackgrounding}
        />
        {serviceStopReason && (
          <p
            style={{
              color: colors.black,
              textAlign: 'center',
              padding: '5px 10px 0px 10px',
              fontSize: '0.7em',
              fontWeight: 400,
            }}
          >
            <b>Last Service Stopped Due To:</b> {serviceStopReason}
          </p>
        )}
      </NeuDiv>
    )
  )
}
