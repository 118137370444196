import { DriveEtaRounded, ShoppingBasketRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, Dialog, ProgressCircular } from 'ui-neumorphism'
import { colors } from '../../../colors'
import { acceptBatchManually } from '../../../hooks/instacart/acceptBatchManually'

export const BatchFinderFocusBatch = ({
  batch,
  setBatch,
  sessionId,
  showAlert,
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [acceptBatchStage, setAcceptBatchStage] = useState(0)

  const formattedStoreStrings = []
  if (batch) {
    for (let i = 0; i < batch.storeBrands.length; i++) {
      formattedStoreStrings.push(
        `${batch.storeBrands[i]} - ${batch.storeAddresses[i]}`,
      )
    }
  }

  const handleAcceptBatch = async () => {
    if (!batch) return
    const res = await acceptBatchManually(
      sessionId,
      batch.batchId,
      batch.metadata,
    )
    if (res.statusCode === 200) {
      navigate({
        pathname: '/batchfinder/active',
        search: searchParams.toString(),
      })
    } else if (res.body === 'Batch no longer available') {
      showAlert(
        'Batch no longer available',
        'Sorry, this batch is no longer available.',
        'user_error',
      )
      setBatch()
    } else {
      showAlert(
        'Failed to accept batch',
        'Oops, there was an issue trying to accept the batch. Please try again.',
        'user_error',
      )
      setBatch()
    }
  }

  return (
    <Dialog visible={!!batch}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
          width: '95vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '10px',
          }}
        >
          {!!batch && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '15px',
                marginTop: '0px',
                marginBottom: '10px',
                borderStyle: 'solid',
                borderRadius: '20px',
                borderColor: colors.blueLight,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    color: colors.black,
                    fontSize: '30px',
                    fontWeight: 'bold',
                    paddingBottom: '5px',
                  }}
                >
                  ${batch.earnings}
                </p>
                <p
                  style={{
                    color: colors.black,
                    fontSize: '20px',
                    fontWeight: 500,
                    paddingBottom: '5px',
                  }}
                >
                  ${batch.tip} Tip
                </p>
              </div>
              <p
                style={{
                  color: colors.black,
                  fontSize: '20px',
                  fontWeight: 500,
                  paddingBottom: '12px',
                }}
              >
                {`${batch.customers} ${
                  batch.customers === 1 ? 'Customer' : 'Customers'
                }`}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <DriveEtaRounded sx={{ color: colors.black }} />
                  <p
                    style={{
                      fontSize: '18px',
                      color: colors.black,
                      paddingLeft: '5px',
                    }}
                  >
                    {batch.distance} mi
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <ShoppingBasketRounded sx={{ color: colors.black }} />
                  <p
                    style={{
                      fontSize: '18px',
                      color: colors.black,
                      paddingLeft: '5px',
                      paddingTop: '3px',
                    }}
                  >
                    {batch.items} Items ({batch.units} Units)
                  </p>
                </div>
              </div>
              {formattedStoreStrings.map((item) => (
                <p
                  style={{
                    fontSize: '17px',
                    color: colors.black,
                  }}
                  key={item}
                >
                  {item}
                </p>
              ))}
            </div>
          )}
          {!!batch && (
            <p
              style={{
                fontSize: '16px',
                fontWeight: 500,
                color: colors.black,
                paddingBottom: '5px',
              }}
            >
              Click Twice to Accept
            </p>
          )}
          {!!batch && (
            <Button
              color="success"
              variant={acceptBatchStage === 0 ? 'outlined' : 'contained'}
              sx={{ fontSize: '20px' }}
              onClick={async () => {
                if (acceptBatchStage >= 1) {
                  await handleAcceptBatch()
                  setAcceptBatchStage(0)
                } else {
                  setAcceptBatchStage(acceptBatchStage + 1)
                }
              }}
            >
              {acceptBatchStage === 0 ? 'Accept Batch' : 'Confirm Accept'}
            </Button>
          )}
          {/* {!!batch && <SlideToAccept handleSuccess={handleAcceptBatch} />} */}
          <Button
            variant="contained"
            sx={{ marginTop: '20px' }}
            onClick={() => {
              setAcceptBatchStage(0)
              setBatch()
            }}
          >
            CLOSE
          </Button>
        </div>
      </Card>
    </Dialog>
  )
}
