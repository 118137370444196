import { useEffect, useState } from 'react'
import { getProfiles } from '../../hooks/instacart/getProfiles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ProfileListItem } from './helpers/ProfileListItem'
import { NeuDiv } from 'neumorphism-react'
import { colors } from '../../colors'
import { Button, Slider, TextField, IconButton, Switch } from '@mui/material'
import { putProfile } from '../../hooks/instacart/putProfile'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { getStoreAddresses } from '../../hooks/instacart/getStoreAddresses'
import { DeleteForeverRounded } from '@mui/icons-material'
import { deleteProfile } from '../../hooks/instacart/deleteProfile'
import { BackButton } from '../../components/BackButton'

export const BatchFinderProfiles = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
}) => {
  const { saveSessionId, handleLogOut } = global
  const [profiles, setProfiles] = useState()
  const [loading, setLoading] = useState(false)
  const [focusProfile, setFocusProfile] = useState()
  const [originalFocusProfile, setOriginalFocusProfile] = useState()
  const [focusProfilePage, setFocusProfilePage] = useState(1)
  const [focusStoreAddressManager, setFocusStoreAddressManager] =
    useState(false)
  const [isAddressWhitelist, setIsAddressWhitelist] = useState()
  const [storeAddresses, setStoreAddresses] = useState()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleGetStoreAddresses = async () => {
    const res = await getStoreAddresses(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      setStoreAddresses(Object.values(res.body.stores))
    } else {
      alert('Error retrieving filters.')
    }
  }

  const handleGetProfiles = async () => {
    setLoading(true)
    const res = await getProfiles(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      const sortedProfiles = res.body.profiles
      sortedProfiles.sort((a, b) => a.created_on.localeCompare(b.created_on))
      setProfiles(res.body.profiles)
    } else {
      alert('Error retrieving filters.')
    }
    setLoading(false)
  }

  const handleSaveProfile = async (profileToSave) => {
    setLoading(true)
    const res = await putProfile(sessionId, profileToSave)
    setLoading(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Reached maximum number of profiles') {
        setFocusProfilePage(1)
        setFocusProfile()
        setOriginalFocusProfile()
        alert('Reached maximum number of profiles (30)')
      }
    } else if (res.statusCode === 200) {
      setFocusProfilePage(1)
      setFocusProfile()
      setOriginalFocusProfile()
      handleGetProfiles()
    } else {
      alert('Error saving profile')
    }
  }

  const createNewProfile = () => {
    const newProfileSchema = {
      name: `Profile #${profiles ? profiles.length + 1 : 1}`,
      enabled: true,
      min_earnings: 30,
      min_tip: 0,
      max_items: 0,
      max_units: 0,
      max_distance: 0,
      max_store_distance: 0,
      max_customers: 0,
      max_stores: 0,
      store_brand_blacklist: [],
      store_brand_whitelist: [],
      store_address_whitelist: [],
      store_address_blacklist: [],
    }
    setOriginalFocusProfile(JSON.parse(JSON.stringify(newProfileSchema)))
    setIsAddressWhitelist(true)
    setFocusProfile(newProfileSchema)
  }

  const handleDeleteProfile = async () => {
    const answer = window.confirm('Delete Profile?')
    if (!answer) return
    const res = await deleteProfile(sessionId, focusProfile.profile_id)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      setFocusProfilePage(1)
      setFocusProfile()
      setOriginalFocusProfile()
      handleGetProfiles()
      alert('Profile Deleted')
    } else {
      alert('Error deleting profiles.')
    }
  }

  useEffect(() => {
    if (profiles === undefined && sessionId) {
      handleGetProfiles()
    }
  })

  useEffect(() => {
    if (storeAddresses === undefined && sessionId) {
      handleGetStoreAddresses()
    }
  })

  const dollarMarks = [0, 20, 40, 60, 80, 100].map((item) => {
    if (item === 0) return { value: item, label: `Disable` }
    return { value: item, label: `$${item}` }
  })
  const distanceMarks = [0, 5, 10, 15, 20, 25, 30].map((item) => {
    if (item === 0) return { value: item, label: `Disable` }
    return { value: item, label: `${item}` }
  })
  const itemMarks = [0, 40, 80, 120, 160, 200].map((item) => {
    if (item === 0) return { value: item, label: `Disable` }
    return { value: item, label: `${item}` }
  })
  const unitMarks = [0, 40, 80, 120, 160, 200].map((item) => {
    if (item === 0) return { value: item, label: `Disable` }
    return { value: item, label: `${item}` }
  })
  const customerMarks = [0, 1, 2, 3].map((item) => {
    if (item === 0) return { value: item, label: `Disable` }
    return { value: item, label: `${item}` }
  })
  const storeCountMarks = [0, 1, 2, 3].map((item) => {
    if (item === 0) return { value: item, label: `Disable` }
    return { value: item, label: `${item}` }
  })

  const valueLabelFormat = (value) => {
    return value === 0 ? 'Disable' : value
  }

  const isOnStoreAddressList = (brand, address) => {
    const listType = isAddressWhitelist
      ? 'store_address_whitelist'
      : 'store_address_blacklist'
    for (let storeObj of focusProfile[listType]) {
      if (storeObj.brand === brand && storeObj.address === address) {
        return true
      }
    }
    return false
  }

  const handleStoreAddressSwitchClick = (checked, brand, address) => {
    const listType = isAddressWhitelist
      ? 'store_address_whitelist'
      : 'store_address_blacklist'
    const newStoreList = JSON.parse(JSON.stringify(focusProfile[listType]))
    if (checked) {
      newStoreList.push({
        brand,
        address,
      })
    } else {
      for (let i = 0; i < newStoreList.length; i++) {
        const storeObj = newStoreList[i]
        if (storeObj.brand === brand && storeObj.address === address) {
          newStoreList.splice(i, 1)
          break
        }
      }
    }
    setFocusProfile({
      ...focusProfile,
      [listType]: newStoreList,
    })
  }

  const setOrderedStoreAddresses = (profile) => {
    const enabledStores = []
    const disabledStores = []
    const listType =
      (!profile.store_address_blacklist.length &&
        !profile.store_address_whitelist.length) ||
      profile.store_address_whitelist.length
        ? 'store_address_whitelist'
        : 'store_address_blacklist'
    const copiedStoreAddresses = JSON.parse(JSON.stringify(storeAddresses))
    copiedStoreAddresses.sort((a, b) =>
      `${a.brand} - ${a.address}`.localeCompare(`${b.brand} - ${b.address}`),
    )
    for (let storeObj of copiedStoreAddresses) {
      let isEnabled = false
      for (let selectedObj of profile[listType]) {
        if (
          storeObj.brand === selectedObj.brand &&
          storeObj.address === selectedObj.address
        ) {
          enabledStores.push(storeObj)
          isEnabled = true
          break
        }
      }
      if (!isEnabled) {
        disabledStores.push(storeObj)
      }
    }
    setStoreAddresses([...enabledStores, ...disabledStores])
  }

  return (
    <div
      class="noselect"
      style={{
        overflowY: 'clip',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowY: 'clip',
        }}
      >
        {loading && <LoadingSpinner />}
        <h1
          style={{
            paddingTop: '2vh',
          }}
        >
          BatchFinder Filters
        </h1>
        {!focusProfile && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '80vh',
                width: '97vw',
                overflowY: 'scroll',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '10px 10px',
                borderStyle: 'solid',
                borderColor: '#1976D2',
                borderWidth: '2px',
                borderRadius: '20px',
              }}
            >
              {profiles !== undefined &&
                profiles.map((profile) => (
                  <NeuDiv
                    key={profile.profile_id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '50px',
                      maxHeight: '50px',
                      minHeight: '50px',
                      margin: '0px 0px 20px 0px',
                    }}
                    color={colors.offWhite}
                    distance={2}
                    revert
                  >
                    <ProfileListItem
                      profile={profile}
                      setFocusProfile={setFocusProfile}
                      setIsAddressWhitelist={setIsAddressWhitelist}
                      setOrderedStoreAddresses={setOrderedStoreAddresses}
                      setOriginalFocusProfile={setOriginalFocusProfile}
                    />
                    <Switch
                      checked={profile.enabled}
                      onChange={(event) => {
                        const updatedProfile = JSON.parse(
                          JSON.stringify(profile),
                        )
                        updatedProfile.enabled = event.target.checked
                        handleSaveProfile(updatedProfile)
                      }}
                    />
                  </NeuDiv>
                ))}
            </div>
            {profiles?.length < 30 && (
              <Button
                style={{
                  width: 'auto',
                  height: '40px',
                  marginTop: '10px',
                  textTransform: 'none',
                }}
                onClick={createNewProfile}
                variant="contained"
              >
                Create New Filter Profile
              </Button>
            )}
          </>
        )}
        {!focusStoreAddressManager && focusProfile && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'flex-start',
            }}
          >
            {focusProfilePage === 1 && (
              <>
                <TextField
                  label="Filter Name"
                  defaultValue={originalFocusProfile.name}
                  onChange={(event) =>
                    setFocusProfile({
                      ...focusProfile,
                      name: event.target.value,
                    })
                  }
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  variant="standard"
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Minimum Earnings (total): </b>
                  {focusProfile.min_earnings === 0
                    ? 'No Preference'
                    : `$${focusProfile.min_earnings}`}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.min_earnings}
                  step={1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={dollarMarks}
                  min={0}
                  max={100}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({ ...focusProfile, min_earnings: newValue })
                  }}
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Minimum Tip: </b>
                  {focusProfile.min_tip === 0
                    ? 'No Preference'
                    : `$${focusProfile.min_tip}`}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.min_tip}
                  step={1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={dollarMarks}
                  min={0}
                  max={100}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({ ...focusProfile, min_tip: newValue })
                  }}
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Max Total Dropoff Distance: </b>
                  {focusProfile.max_distance === 0
                    ? 'No Preference'
                    : `${focusProfile.max_distance} Miles`}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.max_distance}
                  step={0.1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={distanceMarks}
                  min={0}
                  max={30}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({ ...focusProfile, max_distance: newValue })
                  }}
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Max Items: </b>
                  {focusProfile.max_items === 0
                    ? 'No Preference'
                    : focusProfile.max_items}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.max_items}
                  step={1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={itemMarks}
                  min={0}
                  max={200}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({ ...focusProfile, max_items: newValue })
                  }}
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Max Units: </b>
                  {focusProfile.max_units === 0
                    ? 'No Preference'
                    : focusProfile.max_units}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.max_units}
                  step={1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={unitMarks}
                  min={0}
                  max={200}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({ ...focusProfile, max_units: newValue })
                  }}
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Max Customers: </b>
                  {focusProfile.max_customers === 0
                    ? 'No Preference'
                    : focusProfile.max_customers}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.max_customers}
                  step={1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={customerMarks}
                  min={0}
                  max={3}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({
                      ...focusProfile,
                      max_customers: newValue,
                    })
                  }}
                />
              </>
            )}
            {focusProfilePage === 2 && (
              <>
                <p
                  style={{
                    marginTop: '10px',
                    marginBottom: '-12px',
                    marginTop: '20px',
                  }}
                >
                  <b>Max Distance To Store: </b>
                  {focusProfile.max_store_distance === 0
                    ? 'No Preference'
                    : focusProfile.max_store_distance + ' Miles'}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.max_store_distance}
                  step={0.1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={distanceMarks}
                  min={0}
                  max={30}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({
                      ...focusProfile,
                      max_store_distance: newValue,
                    })
                  }}
                />
                <p style={{ marginTop: '10px', marginBottom: '-12px' }}>
                  <b>Max Number of Stores: </b>
                  {focusProfile.max_stores === 0
                    ? 'No Preference'
                    : focusProfile.max_stores}
                </p>
                <Slider
                  defaultValue={originalFocusProfile.max_stores}
                  step={1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  marks={storeCountMarks}
                  min={0}
                  max={3}
                  sx={{
                    width: '80vw',
                  }}
                  onChange={(event, newValue) => {
                    setFocusProfile({ ...focusProfile, max_stores: newValue })
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    margin: '0px 10px',
                    height: '40px',
                    marginTop: '10px',
                    alignSelf: 'center',
                  }}
                  onClick={() => setFocusStoreAddressManager(true)}
                >
                  Filter by Store Address
                </Button>
              </>
            )}
            {!focusStoreAddressManager && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  position: 'absolute',
                  bottom: '5%',
                  left: '50%',
                  transform: 'translate(-50%, 0%)',
                }}
              >
                <IconButton
                  size="large"
                  disabled={focusProfilePage === 1}
                  onClick={() => setFocusProfilePage(focusProfilePage - 1)}
                >
                  <ArrowBackRoundedIcon
                    sx={{
                      fontSize: '30px',
                      color: focusProfilePage === 1 ? '#B2B2B2' : colors.black,
                    }}
                  />
                </IconButton>
                <Button
                  variant="contained"
                  sx={{ width: '50%', margin: '0px 10px', height: '40px' }}
                  onClick={() => handleSaveProfile(focusProfile)}
                >
                  Save & Close
                </Button>
                <IconButton
                  size="large"
                  disabled={focusProfilePage === 2}
                  onClick={() => setFocusProfilePage(focusProfilePage + 1)}
                >
                  <ArrowForwardRoundedIcon
                    sx={{
                      fontSize: '30px',
                      color: focusProfilePage === 2 ? '#B2B2B2' : colors.black,
                    }}
                  />
                </IconButton>
              </div>
            )}
            {!focusStoreAddressManager && (
              <DeleteForeverRounded
                sx={{
                  position: 'absolute',
                  fontSize: '30px',
                  color: colors.doordash_red,
                  bottom: '15px',
                  right: '15px',
                }}
                onClick={handleDeleteProfile}
              />
            )}
          </div>
        )}
        {focusStoreAddressManager && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'flex-start',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  backgroundColor: isAddressWhitelist
                    ? '#1976D2'
                    : colors.offWhite,
                  width: '30%',
                  height: '35px',
                  borderRadius: '15px 0px 0px 15px',
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: '#1976D2',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setFocusProfile({
                    ...focusProfile,
                    store_address_blacklist: [],
                  })
                  setIsAddressWhitelist(true)
                }}
              >
                <p
                  style={{
                    color: isAddressWhitelist ? 'white' : colors.gray500,
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Allow Stores
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: isAddressWhitelist
                    ? colors.offWhite
                    : '#1976D2',
                  width: '30%',
                  height: '35px',
                  borderRadius: '0px 15px 15px 0px',
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: '#1976D2',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setFocusProfile({
                    ...focusProfile,
                    store_address_whitelist: [],
                  })
                  setIsAddressWhitelist(false)
                }}
              >
                <p
                  style={{
                    color: isAddressWhitelist ? colors.gray500 : 'white',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Ban Stores
                </p>
              </div>
            </div>
            {isAddressWhitelist && (
              <p
                style={{
                  textAlign: 'center',
                  padding: '10px 30px',
                }}
              >
                Select the stores you <b>only want</b> to shop at. BatchFinder
                will <b>only</b> accept batches from these stores.
              </p>
            )}
            {!isAddressWhitelist && (
              <p
                style={{
                  textAlign: 'center',
                  padding: '10px 30px',
                }}
              >
                Select the stores you <b>don't want</b> to shop at. BatchFinder
                will <b>ignore</b> batches from these stores.
              </p>
            )}
            <NeuDiv
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                overflowY: 'scroll',
                height: '65vh',
                padding: '10px',
              }}
              color={colors.offWhite}
              distance={2}
              revert
            >
              {!!storeAddresses.length &&
                storeAddresses.map((storeObj) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '5px',
                      minHeight: '38px',
                    }}
                    key={`${storeObj.brand} - ${storeObj.address}`}
                  >
                    <p
                      style={{
                        color: colors.black,
                        fontSize: '14px',
                      }}
                    >
                      <b>{storeObj.brand} - </b> {storeObj.address}
                    </p>
                    <Switch
                      checked={isOnStoreAddressList(
                        storeObj.brand,
                        storeObj.address,
                      )}
                      onClick={(event) =>
                        handleStoreAddressSwitchClick(
                          event.target.checked,
                          storeObj.brand,
                          storeObj.address,
                        )
                      }
                    />
                  </div>
                ))}
              {!storeAddresses.length && (
                <>
                  <p
                    style={{
                      color: colors.doordash_red,
                      fontSize: '18px',
                      padding: '10px 10px 5px 10px',
                      textAlign: 'center',
                      fontWeight: 600,
                    }}
                  >
                    No store addresses found yet.
                  </p>
                  <p
                    style={{
                      color: colors.doordash_red,
                      fontSize: '16px',
                      padding: '0px 10px',
                      textAlign: 'center',
                    }}
                  >
                    Run the BatchFinder app for a few minutes while batches are
                    available, then come back.
                  </p>
                </>
              )}
            </NeuDiv>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                position: 'absolute',
                bottom: '5%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
              }}
            >
              <Button
                variant="contained"
                sx={{ width: '50%', margin: '0px 10px', height: '40px' }}
                onClick={() => setFocusStoreAddressManager(false)}
              >
                Back
              </Button>
            </div>
          </div>
        )}
        {!focusProfile && <BackButton />}
      </div>
    </div>
  )
}
