import { useState } from 'react'
import { colors } from '../../../colors'
import './index.css'
import { Premium } from './Premium'
import { Basic } from './Basic'
import { PremiumCard } from '../PremiumCard'
import { StandardCard } from '../StandardCard'
import { Turbo } from './Turbo'

export const PurchaseOptions = ({ _displayLicenseType }) => {
  const [focusedLicense, _focusedLicense] = useState(null)

  return (
    <div>
      {focusedLicense === null && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginTop: '2rem',
          }}
        >
          <Turbo onClick={() => _displayLicenseType('turbo')} />
          <Premium onClick={() => _displayLicenseType('premium')} />
          <Basic onClick={() => _displayLicenseType('standard')} />
        </div>
      )}
    </div>
  )
}
