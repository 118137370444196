import { colors } from '../../colors'

const FilterItem = ({ label }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: colors.splashDark,
        padding: '10px',
        borderRadius: '10px',
        height: '100%',
      }}
    >
      <p style={{ color: colors.splashGreen, justifySelf: 'center' }}>
        {label}
      </p>
    </div>
  )
}

const FilterItemSplit = ({ firstWord, secondWord }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: colors.splashDark,
        padding: '10px',
        borderRadius: '10px',
        height: '100%',
      }}
    >
      <p style={{ color: colors.splashGreen, justifySelf: 'center' }}>
        {firstWord} <br /> {secondWord}
      </p>
    </div>
  )
}

export const Onboarding_3 = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.splashDark,
        color: colors.white,
        padding: '50px 20px 20px 20px',
        textAlign: 'center',
      }}
    >
      <h1
        style={{ fontSize: '1.8rem', fontWeight: 500, padding: '0 0 2rem 0' }}
        className="slide-in"
      >
        How Filters Work
      </h1>
      <div
        style={{
          width: '90%',
          backgroundColor: '#404040',
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
        }}
        className="slide-in"
      >
        <p>Filters allow you to specify what batches you want to accept.</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr 1fr',
            gap: '24px',
            width: '90%',
            padding: '1.5rem 0rem',
          }}
        >
          <FilterItemSplit firstWord="Minimum" secondWord="Earnings" />
          <FilterItem label="Max Items" />
          <FilterItemSplit firstWord="Max" secondWord="Distance" />
          <FilterItem label="Max Units" />
          <FilterItemSplit firstWord="Max" secondWord="Customers" />
          <FilterItemSplit firstWord="Minimum" secondWord="Tip" />
        </div>
        <p>
          If a batch fits all of the criteria of one of your filters, it will be
          accepted.
        </p>
      </div>
    </div>
  )
}
