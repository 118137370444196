import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { Card, Dialog, ProgressCircular } from 'ui-neumorphism'
import { colors } from '../../../colors'

export const BatchFinderStartupDialog = ({
  pingAlive,
  getProfiles,
  geoLocation,
  getStores,
  checkActive,
  reportLocation,
  getBatches,
}) => {
  const stages = [
    { name: 'Handshake', state: pingAlive },
    { name: 'Geo', state: getProfiles },
    { name: 'Filters', state: geoLocation },
    { name: 'Batches', state: getBatches },
    { name: 'Stores', state: getStores },
    { name: 'Location', state: reportLocation },
    { name: 'Active', state: checkActive },
  ]

  return (
    <Dialog visible={!stages.map((item) => item.state).every((i) => i)}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
          width: '90vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: '22px',
                color: colors.black,
                paddingBottom: '15px',
                fontWeight: 500,
              }}
            >
              Starting BatchFinder...
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {stages.map((item) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    width: '60%',
                  }}
                  key={item.name}
                >
                  <p
                    style={{
                      fontSize: '18px',
                      color: colors.black,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}:
                  </p>
                  {item.state ? (
                    <CheckCircleOutlineRounded
                      sx={{
                        color: colors.green,
                        padding: '0px',
                        fontSize: '32px',
                      }}
                    />
                  ) : (
                    <ProgressCircular
                      indeterminate
                      size={24}
                      width={2}
                      color="var(--primary)"
                      style={{}}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}
