import { Button } from '@mui/material'
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

export const BackButton = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  let backPath
  if (
    useMatch('/batchfinder/active') ||
    (location.pathname && location.pathname.split('/').length - 1 === 1) ||
    (location.pathname.endsWith('/') &&
      location.pathname.split('/').length - 1 === 2)
  ) {
    backPath = '/app'
  } else if (location.pathname.endsWith('/')) {
    backPath = location.pathname + '..'
  } else {
    backPath = location.pathname + '/..'
  }

  return (
    <Button
      variant="contained"
      sx={{ position: 'absolute', bottom: '20px', left: '20px' }}
      onClick={() => {
        navigate({
          pathname: backPath,
          search: searchParams.toString(),
        })
      }}
    >
      Back
    </Button>
  )
}
