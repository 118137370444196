import { NeuButton } from 'neumorphism-react'
import { Card, Dialog } from 'ui-neumorphism'
import { colors } from '../../../colors'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const ClaimSuccessDialog = ({ visible, onClose }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <Dialog minWidth={300} visible={visible} onClose={onClose}>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          textAlign: 'center',
          maxWidth: '90vw',
        }}
      >
        <p
          style={{
            fontSize: '28px',
            fontWeight: 600,
            color: colors.blueLight,
            paddingBottom: '20px',
          }}
        >
          Claimed Reward!
        </p>
        <p style={{ fontWeight: 500, fontSize: '22px', paddingBottom: '20px' }}>
          Enjoy the free 7-day license!
        </p>
        <p
          style={{
            fontSize: '18px',
            paddingBottom: '20px',
          }}
        >
          Thank you for supporting BatchFinder. I greatly appreciate you sharing
          BatchFinder to people you trust.
        </p>
        <p
          style={{
            fontSize: '14px',
          }}
          onClick={() =>
            navigate({
              pathname: '/license',
              search: searchParams.toString(),
            })
          }
        >
          The 7-day license has been activated on your account. Click{' '}
          <b>HERE</b> to check via the <b>License Access</b> page.
        </p>
        <NeuButton
          style={{
            width: '40vw',
            height: '30px',
            textTransform: 'none',
            fontSize: '18px',
            color: colors.black,
            marginTop: '20px',
            fontWeight: 500,
          }}
          color={colors.offWhite}
          distance={2}
          onClick={onClose}
        >
          CLOSE
        </NeuButton>
      </Card>
    </Dialog>
  )
}
