import { colors } from '../../../colors'
import './index.css'
import { useEffect, useState } from 'react'

export const Turbo = ({ onClick }) => {
  return (
    <div
      className="animated-border"
      onClick={onClick}
      style={{
        position: 'relative',
        padding: '15px 20px',
        borderRadius: '12px',
        cursor: 'pointer',
        backgroundColor: '#FBFAF8',
      }}
    >
      <span
        style={{
          position: 'absolute',
          top: '10px',
          right: '15px',
          fontSize: '2rem',
        }}
      >
        ⚡
      </span>
      <h3 style={{ color: 'white' }}>Turbocharged</h3>
      <p style={{ color: 'white', fontSize: '0.9rem' }}>
        For Shoppers in competitive areas
      </p>
      <div
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: 'white',
          margin: '10px 0',
        }}
      />
      <p style={{ marginLeft: '5px', color: 'white' }}>
        <b>60x Faster</b> vs. Premium
      </p>
    </div>
  )
}
