import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { stopBatchFinderService } from '../../../../hooks/stopBatchFinderService'

export const StopTurbo = ({
  global,
  sessionId,
  turboRunning,
  _turboRunning,
  handleGetServiceSettings,
}) => {
  const { handleLogOut, showAlert, setIsLoading } = global

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const onClick = async () => {
    setIsLoading(true)
    const res = await stopBatchFinderService(sessionId)
    setIsLoading(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        showAlert(
          'Error stopping BatchFinder',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      handleGetServiceSettings()
      _turboRunning(false)
    } else {
      showAlert(
        'Error stopping BatchFinder',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  return (
    turboRunning && (
      <Button
        variant="contained"
        color="error"
        sx={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          fontSize: '12px',
        }}
        onClick={onClick}
      >
        Stop
      </Button>
    )
  )
}
