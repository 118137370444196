import { CircularProgress, circularProgressClasses } from '@mui/material'
import { colors } from '../../../colors'

export const CustomLoader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        zIndex: 1,
        backgroundColor: colors.loading,
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '30px 20px 50px 20px',
          borderRadius: '10px',
          textAlign: 'center',
          width: '80vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2>Starting Batch</h2>
        <p>This can take up to 30 seconds.</p>
        <div
          style={{
            position: 'relative',
            width: '50px',
            height: '50px', // The height of CircularProgress + any margin
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '60px 0px 30px 0px',
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
            }}
            size={40}
            thickness={4}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
              animationDuration: '550ms',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={40}
            thickness={4}
          />
        </div>
      </div>
    </div>
  )
}
