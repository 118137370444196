import { colors } from '../../colors'
import onboardingSplash from './batchfinder_onboarding.png'

export const Onboarding_1 = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.splashDark,
        color: colors.white,
        padding: '50px 20px 20px 20px',
        textAlign: 'center',
      }}
    >
      <h1
        style={{ fontSize: '1.8rem', fontWeight: 500, padding: '8px 0' }}
        className="slide-in"
      >
        Welcome to BatchFinder!
      </h1>
      <p className="slide-in">
        Your go-to tool for finding the best batches on Instacart.
      </p>
      <img
        src={onboardingSplash}
        alt="onboardingSplash"
        style={{
          width: '80vw',
          padding: '3rem 0',
        }}
        className="slide-in"
      />
    </div>
  )
}
