import { BatchFinderRouteLink } from './helpers/BatchFinderRouteLink'
import carrotIcon from '../../icons/carrotIcon.png'
import { useEffect, useState } from 'react'
import { checkCredentials } from '../../hooks/checkCredentials'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import { colors } from '../../colors'
import { getActiveBatch } from '../../hooks/instacart/getActiveBatch'
import { NeuButton } from 'neumorphism-react'
import {
  ExitToAppRounded,
  GroupAddRounded,
  HelpOutlineRounded,
  MenuBookRounded,
} from '@mui/icons-material'
import { BatchFinderMenuButton } from './helpers/BatchFinderMenuButton'
import { unauth } from '../../hooks/instacart/unauth'
import { BackButton } from '../../components/BackButton'
import { Helmet } from 'react-helmet'
import { getBatchFinderServiceSettings } from '../../hooks/getBatchFinderServiceSettings'

export const BatchFinder = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  userViews,
}) => {
  const { saveSessionId, handleLogOut, showAlert } = global
  const [loading, setLoading] = useState(false)
  const [isCheckingCredentials, setIsCheckingCredentials] = useState(false)
  const [isCheckingActiveBatch, setIsCheckingActiveBatch] = useState(false)
  const [hasValidCredentials, setHasValidCredentials] = useState()
  const [hasActiveBatch, setHasActiveBatch] = useState()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [serviceNameRunning, _serviceNameRunning] = useState()

  const handleCheckCredentials = async () => {
    setLoading(true)
    const res = await checkCredentials(sessionId, 'instacart')
    setIsCheckingCredentials(false)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      setHasValidCredentials(res.body.tokens)
    } else {
      showAlert(
        'Error validating credentials',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
    setLoading(false)
  }

  const handleGetActiveBatch = async () => {
    const res = await getActiveBatch(sessionId)
    setIsCheckingActiveBatch(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Rate limited') {
        // Do nothing
      } else {
        showAlert(
          'Error checking active',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
        setHasActiveBatch(false)
      }
    } else if (res.statusCode === 200) {
      if (res.body.batch) {
        setHasActiveBatch(true)
        navigate({
          pathname: '/batchfinder/active',
          search: searchParams.toString(),
        })
      } else {
        setHasActiveBatch(false)
      }
    } else {
      showAlert(
        'Error checking active',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleInstacartUnauth = async () => {
    setLoading(true)
    const res = await unauth(sessionId)
    setLoading(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        alert('Error with unauth process:', JSON.stringify(res))
      }
    } else if (res.statusCode === 200) {
      await handleCheckCredentials()
    } else {
      alert('Error with unauth process:', JSON.stringify(res))
    }
  }

  const handleGetServiceSettings = async () => {
    const res = await getBatchFinderServiceSettings(sessionId)
    if (res.body === 'Invalid session') {
      handleLogOut()
    } else if (res.statusCode === 200) {
      const instacart_settings = res.body.step_settings?.instacart
      if (instacart_settings?.step_function_id)
        _serviceNameRunning(instacart_settings.step_function_name)
    }
  }

  useEffect(() => {
    if (sessionId) handleGetServiceSettings()
  }, [sessionId])

  useEffect(() => {
    if (
      hasValidCredentials === undefined &&
      sessionId &&
      !isCheckingCredentials
    ) {
      setIsCheckingCredentials(true)
      handleCheckCredentials()
    }
  })

  useEffect(() => {
    if (
      hasActiveBatch === undefined &&
      sessionId &&
      hasValidCredentials !== undefined &&
      !isCheckingActiveBatch
    ) {
      if (hasValidCredentials === false) {
        setHasActiveBatch(false)
      } else {
        setIsCheckingActiveBatch(true)
        handleGetActiveBatch()
      }
    }
  })

  return (
    <div class="noselect">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {loading && <LoadingSpinner />}
        <h1
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          BatchFinder
        </h1>
        {!hasValidCredentials && (
          <BatchFinderRouteLink
            label="Setup"
            to="/batchfinder/setup"
            icon={
              <img
                src={carrotIcon}
                alt="carrot"
                style={{
                  height: '30px',
                  marginRight: 'auto',
                }}
              />
            }
            disabled={!sessionId || !currentLicense}
          />
        )}
        {hasValidCredentials && (
          <BatchFinderRouteLink
            label="App"
            to="/batchfinder/app"
            icon={
              <img
                src={carrotIcon}
                alt="carrot"
                style={{
                  height: '30px',
                  marginRight: 'auto',
                }}
              />
            }
            disabled={!sessionId || !currentLicense}
          />
        )}
        {hasValidCredentials && (
          <BatchFinderRouteLink
            label="Premium App"
            to="/batchfinder/service"
            icon={
              <img
                src={carrotIcon}
                alt="carrot"
                style={{
                  height: '30px',
                  marginRight: 'auto',
                }}
              />
            }
            disabled={
              !sessionId ||
              (currentLicense?.license_variant !== 'premium' &&
                currentLicense?.license_variant !== 'turbo')
            }
            glowText={true}
            isRunning={serviceNameRunning === 'BatchFinder'}
          />
        )}
        {hasValidCredentials && (
          <BatchFinderRouteLink
            label="⚡ Turbo App ⚡"
            to="/batchfinder/turbo"
            icon={
              <img
                src={carrotIcon}
                alt="carrot"
                style={{
                  height: '30px',
                  marginRight: 'auto',
                }}
              />
            }
            disabled={!sessionId || currentLicense?.license_variant !== 'turbo'}
            turboText={true}
            isRunning={serviceNameRunning === 'BatchFinder-Turbo'}
          />
        )}
        <BatchFinderRouteLink
          label="Filters"
          to="/batchfinder/filters"
          icon={
            <TuneRoundedIcon
              color={colors.black}
              sx={{
                marginRight: 'auto',
                fontSize: 28,
              }}
            />
          }
          disabled={!sessionId}
        />
        <BatchFinderMenuButton
          label="Earn Free Licenses"
          subLabel="Referral System"
          icon={
            <GroupAddRounded
              color={colors.black}
              sx={{
                marginRight: 'auto',
                fontSize: 28,
              }}
            />
          }
          disabled={!sessionId}
          onClick={() =>
            navigate({
              pathname: '/referral',
              search: searchParams.toString(),
            })
          }
        />
        <BatchFinderRouteLink
          label="Setup Guide"
          to="/batchfinder/guide"
          icon={
            <MenuBookRounded
              color={colors.black}
              sx={{
                marginRight: 'auto',
                fontSize: 28,
              }}
            />
          }
        />
        {hasValidCredentials && (
          <BatchFinderMenuButton
            label="Disconnect Shopper"
            subLabel="Click before signing into Shopper"
            icon={
              <ExitToAppRounded
                color={colors.black}
                sx={{
                  marginRight: 'auto',
                  fontSize: 28,
                }}
              />
            }
            onClick={handleInstacartUnauth}
          />
        )}
        {hasValidCredentials && (
          <p
            style={{
              padding: '0px 15vw',
              marginTop: '-20px',
              textAlign: 'center',
              fontSize: '12px',
              fontWeight: 600,
            }}
          >
            If you want to sign into the Shopper app, click the above button
            first to avoid detection.
          </p>
        )}
        {/* <BatchFinderRouteLink
          label="FAQ"
          subLabel="Frequently Asked Questions"
          to="/batchfinder/faq"
          icon={
            <HelpOutlineRounded
              color={colors.black}
              sx={{
                marginRight: 'auto',
                fontSize: 28,
              }}
            />
          }
        /> */}
        {!sessionId && (
          <>
            <p
              style={{
                color: colors.red,
              }}
            >
              Account required. Please log in first.
            </p>
            <NeuButton
              color={colors.offWhite}
              distance={2}
              style={{
                width: 'auto',
                height: '35px',
                fontSize: '18px',
                padding: '0px 20px',
                fontWeight: 500,
                color: colors.black,
                marginTop: '5px',
              }}
              onClick={() =>
                navigate({
                  pathname: '/login',
                  search: searchParams.toString(),
                })
              }
            >
              Click here to log in
            </NeuButton>
          </>
        )}
        {sessionId && !currentLicense && (
          <>
            <p
              style={{
                color: colors.red,
                textAlign: 'center',
                padding: '0px 30px',
              }}
            >
              License required. Trial license keys are available on the License
              page.
            </p>
            <NeuButton
              color={colors.offWhite}
              distance={2}
              style={{
                width: 'auto',
                height: '35px',
                fontSize: '18px',
                padding: '0px 20px',
                fontWeight: 500,
                color: colors.black,
                marginTop: '5px',
              }}
              onClick={() =>
                navigate({
                  pathname: '/license',
                  search: searchParams.toString(),
                })
              }
            >
              Click here to view licenses
            </NeuButton>
          </>
        )}
        <BackButton />
      </div>
      <p
        style={{
          color: 'black',
          fontSize: '12px',
          position: 'absolute',
          bottom: '2vh',
          right: '4vh',
        }}
      >
        v1.1.7
      </p>
    </div>
  )
}
