import { Link, useLocation } from 'react-router-dom'
import { colors } from '../../colors'

export const StartButton = (props) => {
  const location = useLocation()

  return (
    <Link
      style={{
        width: '10.5rem',
        height: '3rem',
        fontSize: '1rem',
        fontWeight: 800,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: colors.splashGreen,
        color: colors.splashDark,
        border: 'none',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        transition: 'transform 0.1s, filter 0.1s',
        textDecoration: 'none',
      }}
      onTouchStart={(e) => {
        e.currentTarget.style.transform = 'scale(0.95)'
      }}
      onTouchEnd={(e) => {
        e.currentTarget.style.transform = 'scale(1)'
      }}
      to={`/app${location.search}`}
    >
      {props.children}
    </Link>
  )
}
