import { NeuDiv } from 'neumorphism-react'
import { colors } from '../../../../colors'
import { BatchListItem } from '../../helpers/BatchListItem'
import { ProgressCircular } from 'ui-neumorphism'
import { useEffect, useState } from 'react'

export const TurboRunning = ({ turboRunning, batches, _focusBatch }) => {
  // const [last_timestamp_action, _last_timestamp_action] = useState('')

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const action = Math.random().toString(36).substring(2, 8).toUpperCase()
  //     const now = new Date()
  //     const timeString = `${String(now.getMinutes()).padStart(2, '0')}:${String(
  //       now.getSeconds(),
  //     ).padStart(2, '0')}.${String(now.getMilliseconds()).padStart(3, '0')}`
  //     _last_timestamp_action(`${action} - ${timeString}`)
  //   }, 100)
  //   return () => clearInterval(intervalId)
  // })

  return (
    turboRunning && (
      <>
        <p
          style={{
            textAlign: 'center',
            color: 'red',
            fontSize: '14px',
            marginTop: '10px',
            marginBottom: '5px',
            fontWeight: 500,
          }}
        >
          BatchFinder will run in the background
        </p>
        <NeuDiv
          style={{
            width: '95vw',
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
          color={colors.offWhite}
          distance={2}
          revert
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                fontWeight: 500,
                fontSize: '20px',
                color: colors.black,
              }}
            >
              Available Batches: {batches?.length}
            </p>
            <ProgressCircular
              indeterminate
              size={24}
              width={2}
              color="var(--primary)"
              style={{
                marginLeft: '20px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            />
          </div>

          {batches.map((item) => (
            <BatchListItem
              key={item.acceptLink}
              batch={item}
              onClick={() => _focusBatch(item)}
            />
          ))}
        </NeuDiv>
        {/* <p>LTSA: {last_timestamp_action}</p> */}
      </>
    )
  )
}
