import { ContentCopyRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { NeuDiv } from 'neumorphism-react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Chip } from 'ui-neumorphism'
import { colors } from '../../colors'
import { BackButton } from '../../components/BackButton'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { claimReferralReward } from '../../hooks/claimReferralReward'
import { getReferralHistory } from '../../hooks/getReferralHistory'
import { ClaimSuccessDialog } from './helpers/ClaimSuccessDialog'

export const Referral = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  openCopySnackbar,
  accountId,
  handleGetCurrentLicense,
}) => {
  const { saveSessionId, handleLogOut } = global
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [referralHistory, setReferralHistory] = useState()
  const [showClaimSuccess, setShowClaimSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)

  const handleGetReferralHistory = async () => {
    setLoading(true)
    const res = await getReferralHistory(sessionId)
    setLoading(false)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      const sortedHistory = res.body.referrals
      sortedHistory.sort(
        (a, b) =>
          new Date(a.created_on).getTime() - new Date(b.created_on).getTime(),
      )
      sortedHistory.sort((a, b) => {
        let aVal = 0
        let bVal = 0
        if (a.completed) aVal -= 1
        if (a.claimed) aVal += 2
        if (b.completed) bVal -= 1
        if (b.claimed) bVal += 2
        return aVal - bVal
      })
      setReferralHistory(sortedHistory)
    } else {
      setReferralHistory([])
      alert('Error getting account referral history:', JSON.stringify(res))
    }
  }

  const handleClaimReward = async (refereeId) => {
    setLoading(true)
    const res = await claimReferralReward(sessionId, refereeId)
    setLoading(false)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      await handleGetReferralHistory()
      handleGetCurrentLicense() //No need to await this
      setShowClaimSuccess(true)
    } else {
      alert('Error getting claiming referral reward:', JSON.stringify(res))
    }
  }

  useEffect(() => {
    if (sessionId && !referralHistory) {
      handleGetReferralHistory()
    }
  })

  useEffect(() => {
    if (firstLoad && sessionId && accountId && referralHistory) {
      setLoading(false)
      setFirstLoad(false)
    }
  })

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(`https://www.avalon.rip?ref=${accountId}`)
    openCopySnackbar()
  }

  return (
    <div class="noselect">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {loading && <LoadingSpinner />}
        <h1
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          Invite Your Friends
        </h1>
        <p
          style={{
            color: colors.black,
            fontSize: '20px',
            fontWeight: 500,
            paddingBottom: '10px',
          }}
          onClick={handleCopyReferralLink}
        >
          Your referral link (click to copy)
        </p>
        <div onClick={handleCopyReferralLink}>
          <Chip
            action={
              <ContentCopyRounded
                sx={{
                  fontSize: '20px',
                  color: colors.black,
                }}
              />
            }
            type="info"
            style={{
              fontSize: '18px',
              fontWeight: 600,
              color: colors.blueLight,
            }}
          >
            www.avalon.rip?ref={accountId}&nbsp;
          </Chip>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '10px 10vw 0px 10vw',
            color: colors.black,
          }}
        >
          <p
            style={{
              fontSize: '20px',
              fontWeight: 600,
              paddingBottom: '5px',
              textAlign: 'center',
              color: colors.green,
            }}
          >
            Share this link with friends
          </p>
          <p
            style={{
              textAlign: 'start',
              paddingBottom: '5px',
              fontSize: '18px',
            }}
          >
            After a friend:
          </p>
          <p
            style={{
              paddingLeft: '10px',
              fontSize: '18px',
            }}
          >
            1. Creates an account using your link
          </p>
          <p
            style={{
              paddingLeft: '10px',
              paddingBottom: '5px',
              fontSize: '18px',
            }}
          >
            2. Accepts a batch using BatchFinder
          </p>
          <p
            style={{
              fontSize: '22px',
              fontWeight: 600,
              textAlign: 'center',
              color: colors.green,
            }}
          >
            You get a free 7-day license!
          </p>
        </div>
        {!referralHistory?.length && (
          <NeuDiv
            style={{
              textAlign: 'center',
              color: colors.black,
              marginTop: '30px',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100px',
              width: '80vw',
            }}
            revert
            distance={2}
            color={colors.offWhite}
          >
            <p style={{ fontSize: '22px', fontWeight: 500 }}>
              No Referrals Yet
            </p>
            <p style={{ fontSize: '20px', fontWeight: 400 }}>
              Make your first one today!
            </p>
          </NeuDiv>
        )}
        {!!referralHistory?.length && (
          <p
            style={{
              fontWeight: 600,
              textAlign: 'center',
              fontSize: '18px',
              color: colors.black,
              padding: '5px 0px',
            }}
          >
            Current Referrals
          </p>
        )}
        {!!referralHistory?.length && (
          <NeuDiv
            color={colors.offWhite}
            revert
            distance={2}
            style={{
              width: '90vw',
              height: '50vh',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'scroll',
              marginTop: '0px',
              paddingTop: '10px',
            }}
          >
            {referralHistory.map((item) => (
              <div
                style={{
                  minHeight: '100px',
                  maxHeight: '100px',
                  borderStyle: 'solid',
                  borderColor: colors.black,
                  borderRadius: '20px',
                  margin: '0px 10px 10px 10px',
                  padding: '10px 15px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={item.account_id}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '18px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Account: {item.account_id}
                  </p>
                  <p>
                    Status:{' '}
                    <b
                      style={{
                        color: item.completed ? '#1776D1' : '#ff9800',
                      }}
                    >
                      {item.completed ? 'Completed!' : 'Ongoing'}
                    </b>
                  </p>
                </div>
                <Button
                  variant="contained"
                  sx={{ width: '40%', fontWeight: 600 }}
                  disabled={!item.completed || item.claimed}
                  onClick={() => handleClaimReward(item.account_id)}
                >
                  {item.claimed ? 'Claimed!' : 'Claim License'}
                </Button>
              </div>
            ))}
          </NeuDiv>
        )}

        <ClaimSuccessDialog
          visible={showClaimSuccess}
          onClose={() => setShowClaimSuccess(false)}
        />
        <BackButton />
      </div>
    </div>
  )
}
