import axios from 'axios'
import { CLIENT_API_KEY } from '../access_keys'

export const verifyCode = async (account_id, verification_code) => {
  const res = {
    body: '',
    statusCode: 0,
  }
  const config = {
    method: 'POST',
    url: 'https://owworarpe5.execute-api.us-west-1.amazonaws.com/Prod/avalon/verify',
    headers: {
      apikey: CLIENT_API_KEY,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ account_id, verification_code }),
  }
  try {
    const response = await axios(config)
    res.statusCode = 200
    res.body = response.data
    return res
  } catch (error) {
    res.statusCode = error.response.status
    res.body = error.response.data
    return res
  }
}
