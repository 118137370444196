import { Button } from '@mui/material'
import { NeuButton, NeuDiv } from 'neumorphism-react'
import { Link, useMatch, useSearchParams } from 'react-router-dom'
import { colors } from '../colors'

export const HomeRouteLink = ({
  label,
  subLabel,
  to,
  icon,
  fontSize,
  glow,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <div
      class="noselect"
      style={{
        display: 'block',
        marginBottom: '25px',
      }}
    >
      <Link
        to={{ pathname: to, search: searchParams.toString() }}
        style={{ textDecoration: 'none' }}
      >
        <NeuButton
          style={{
            width: '70vw',
            height: '50px',
            textTransform: 'none',
            fontWeight: 'bold',
            color: colors.black,
            animation: glow ? 'glow 1.8s infinite alternate' : 'none',
          }}
          color={colors.offWhite}
          distance={2}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '0px 15px',
              alignItems: 'center',
            }}
          >
            {icon}
            <div
              style={{
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  color: colors.black,
                  fontSize: !!fontSize ? fontSize : 'auto',
                }}
              >
                {label}
              </p>
              {!!subLabel && (
                <p
                  style={{
                    color: colors.gray300,
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {subLabel}
                </p>
              )}
            </div>
          </div>
        </NeuButton>
      </Link>
    </div>
  )
}
