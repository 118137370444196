import { colors } from '../../colors'

export const Header = () => {
  return (
    <div
      style={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.offWhite,
        color: colors.black,
        fontSize: '24px',
        padding: '10px 0px 20px 0px',
        position: 'fixed',
        top: 0,
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontSize: '1em' }}>BatchFinder</h1>
        <p style={{ fontSize: '0.5em' }}>By The Avalon Project</p>
      </div>
    </div>
  )
}
