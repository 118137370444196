import { Card, Dialog, Tooltip } from 'ui-neumorphism'
import { colors } from '../colors'
import { Button as NeuButton } from 'ui-neumorphism'
import { Button } from '@mui/material'
import telegramIcon from '../icons/telegramIcon.png'

export const ContactInfoDialog = ({ visible, hideContactInfo }) => {
  return (
    <Dialog minWidth={300} visible={visible} onClose={hideContactInfo}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px 20px',
          }}
        >
          <NeuButton
            style={{
              marginBottom: '20px',
              width: '100%',
            }}
            onClick={() => window.open('https://t.me/BatchFinderDev', '_blank')}
          >
            <img
              src={telegramIcon}
              alt="telegram"
              style={{
                height: '22px',
                paddingRight: '10px',
              }}
            />{' '}
            @BatchFinderDev{' '}
            <img
              src={telegramIcon}
              alt="telegram"
              style={{
                height: '22px',
                paddingLeft: '10px',
              }}
            />
          </NeuButton>
          <NeuButton
            style={{
              marginBottom: '10px',
              width: '100%',
            }}
            onClick={() =>
              window.open('https://t.me/+IOe5ap0cjYo1OTUx', '_blank')
            }
          >
            <img
              src={telegramIcon}
              alt="telegram"
              style={{
                height: '22px',
                paddingRight: '10px',
              }}
            />{' '}
            Telegram Group{' '}
            <img
              src={telegramIcon}
              alt="telegram"
              style={{
                height: '22px',
                paddingLeft: '10px',
              }}
            />
          </NeuButton>
          <Button
            variant="contained"
            style={{
              marginTop: '20px',
            }}
            onClick={hideContactInfo}
          >
            CLOSE
          </Button>
        </div>
      </Card>
    </Dialog>
  )
}
