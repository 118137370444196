import { colors } from '../../../colors'

export const BatchFinderGettingStarted = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        color: colors.black,
      }}
    >
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>1. </b>Go to{' '}
        <a
          href="https://www.avalon.rip/"
          target="_blank"
          style={{ fontWeight: 500 }}
        >
          https://www.avalon.rip
        </a>{' '}
        (this site) on your phone
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>2. </b>Click <b>Log In</b> and create an account with your Shopper
        account phone number
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>3. </b>Click <b>License Access</b> and activate your trial license
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>4. </b>Go back to the home page. Click <b>BatchFinder</b> and then{' '}
        <b>Filters</b>
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>5. </b>Click <b>Create New Filter Profile</b>
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>6. </b>Modify the criteria of the filter. <b>Remember:</b> a batch
        must fit <b>all</b> of the criteria of a filter to be accepted by
        BatchFinder
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>7. </b>Click <b>Save & Close</b>, then click <b>Back</b>.
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>8. </b>Click <b>Setup</b> and follow the setup instructions
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>9. </b> Click <b>App</b> and BatchFinder will start running
      </p>
      <p
        style={{
          fontWeight: 600,
          textAlign: 'center',
          paddingBottom: '10px',
          fontSize: '1.3em',
        }}
      >
        When BatchFinder Accepts a Batch
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>1. </b> Drive to the store address displayed on BatchFinder
      </p>
      <p
        style={{
          paddingBottom: '10px',
        }}
      >
        <b>2. </b> Click <b>Start Shopping</b> on BatchFinder
      </p>
      <p>
        <b>3. </b> Log back into the Shopper app
      </p>
    </div>
  )
}
