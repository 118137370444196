import { NeuButton } from 'neumorphism-react'
import { colors } from '../colors'
import { useMatch } from 'react-router-dom'

export const HomeDialogButton = ({ onClick, label, icon }) => {
  return (
    <div>
      <NeuButton
        style={{
          width: '70vw',
          height: '50px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: colors.black,
          marginBottom: '25px',
        }}
        color={colors.offWhite}
        distance={2}
        onClick={onClick}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '0px 15px',
          }}
        >
          {icon}
          <p
            style={{
              marginRight: 'auto',
              color: colors.black,
              marginTop: '2px',
            }}
          >
            {label}
          </p>
        </div>
      </NeuButton>
    </div>
  )
}
