import { colors } from '../../../colors'
import './index.css'

export const Premium = ({ onClick }) => {
  return (
    <div
      className="premium"
      onClick={onClick}
      style={{
        padding: '15px 20px',
        borderRadius: '12px',
        cursor: 'pointer',
        backgroundColor: colors.offWhite,
      }}
    >
      <h3 style={{ color: 'white' }}>Premium</h3>
      <p style={{ color: 'white', fontSize: '0.9rem' }}>
        The go-to version for active Shoppers
      </p>
      <div
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: 'black',
          margin: '10px 0',
        }}
      />
      <ul style={{ marginLeft: '20px' }}>
        <li style={{ color: 'black' }}>Runs in the background</li>
        <li style={{ color: 'black' }}>Accepted batch notifications</li>
      </ul>
    </div>
  )
}
