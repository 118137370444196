import { Button } from '@mui/material'
import { Box } from '@mui/system'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useEffect, useState } from 'react'
import { colors } from '../../colors'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { createAccount } from '../../hooks/createAccount'
import { logIn } from '../../hooks/logIn'
import { verifyCode } from '../../hooks/verifyCode'
import { useNavigate } from 'react-router-dom'
import AuthCode from 'react-auth-code-input'
import { NeuButton } from 'neumorphism-react'
import { useSearchParams } from 'react-router-dom'

//AIDOC: Component that renders login and signup page
export const AccessAccount = ({
  global,
  setAccountId,
  setSessionId,
  accountId,
}) => {
  const { saveSessionId, handleLogOut, showAlert } = global
  const [searchParams, setSearchParams] = useSearchParams()
  const [userPhone, setUserPhone] = useState('')
  const [verifyPanelVisible, setVerifyPanelVisible] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    setUserPhone(event)
  }

  const handleAccessAccount = async () => {
    const parsedPhone = userPhone.match(/\d/g).join('').slice(1)
    setLoading(true)
    const loginRes = await logIn(parsedPhone, '1')
    setLoading(false)
    if (loginRes.statusCode === 404 && loginRes.body === 'Account not found') {
      const referralCode = searchParams.get('ref') || undefined
      setLoading(true)
      const createAccountRes = await createAccount(
        parsedPhone,
        '1',
        referralCode,
      )
      setLoading(false)
      if (createAccountRes.statusCode === 200) {
        setAccountId(createAccountRes.body.account_id)
      } else {
        showAlert(
          'Error Creating Account',
          `Raw Error: ${JSON.stringify(createAccountRes)}`,
          'client_error',
        )
      }
      setVerifyPanelVisible(true)
    } else if (loginRes.statusCode === 200) {
      setAccountId(loginRes.body.account_id)
      setVerifyPanelVisible(true)
    } else {
      console.log(loginRes)
      setError(loginRes.body)
    }
  }

  const handleVerifyCode = async () => {
    setLoading(true)
    const verifyRes = await verifyCode(accountId, verificationCode)
    if (verifyRes.statusCode === 200) {
      setSessionId(verifyRes.body.session_id)
      setError('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (
      verifyRes.statusCode === 400 &&
      verifyRes.body === 'Invalid verification code'
    ) {
      setError(verifyRes.body)
    } else if (
      verifyRes.statusCode === 400 &&
      verifyRes.body === 'Expired verification code'
    ) {
      setError(verifyRes.body)
    }
    setLoading(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', //'flex-start',
      }}
    >
      {loading && <LoadingSpinner />}
      <h2 style={{ padding: '20px 0px 50px 0px' }}>
        Access Your Avalon Account
      </h2>
      <h4 style={{ marginBottom: '10px' }}>Please enter your phone number</h4>
      <MuiPhoneNumber
        variant="outlined"
        defaultCountry={'us'}
        disableDropdown
        onChange={handleInputChange}
        countryCodeEditable={false}
        size="small"
      />
      <p
        style={{
          fontWeight: 300,
          fontSize: '12px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingBottom: '20px',
        }}
      >
        By entering your phone number, you opt into receiving automated SMS
        messages related to Avalon.
      </p>
      <Button
        variant="contained"
        disableElevation
        onClick={handleAccessAccount}
        disabled={userPhone.length !== 17}
        sx={{ textTransform: 'none', marginBottom: '40px' }}
      >
        Login or Create Account
      </Button>
      {verifyPanelVisible && (
        <>
          <h2
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: colors.black,
              marginBottom: '10px',
            }}
          >
            Click box to enter code below
          </h2>
          <AuthCode
            allowedCharacters="numeric"
            onChange={setVerificationCode}
            inputClassName="input"
          />
        </>
      )}
      {verificationCode.length === 6 && (
        <NeuButton
          width="60vw"
          height="30px"
          style={{
            marginTop: '30px',
            fontSize: '16px',
            color: colors.black,
            margin: '10px 0px',
          }}
          color={colors.offWhite}
          distance={2}
          onClick={handleVerifyCode}
        >
          Click to Submit
        </NeuButton>
      )}
      <p
        style={{
          color: colors.red,
          marginTop: '20px',
        }}
      >
        {error}
      </p>
    </Box>
  )
}
