import { NeuDiv } from 'neumorphism-react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ProgressCircular } from 'ui-neumorphism'
import { colors } from '../../colors'
import { getActiveBatch } from '../../hooks/instacart/getActiveBatch'
import { getBatches } from '../../hooks/instacart/getBatches'
import { getProfiles } from '../../hooks/instacart/getProfiles'
import { getStoreLocations } from '../../hooks/instacart/getStoreLocations'
import { reportLocation } from '../../hooks/instacart/reportLocation'
import { pingAlive } from '../../hooks/instacart/pingAlive'
import { BatchListItem } from './helpers/BatchListItem'
import { Button } from '@mui/material'
import { BatchFinderFakeGPS } from './helpers/BatchFinderFakeGPS'
import { BatchFinderStartupDialog } from './helpers/BatchFinderStartupDialog'
import { BatchFinderFocusBatch } from './helpers/BatchFinderFocusBatch'
import { BackButton } from '../../components/BackButton'

export const BatchFinderApp = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  accountId,
}) => {
  const {
    saveSessionId,
    handleLogOut,
    showAlert,
    handleGetCurrentLicense,
    acceptedBatchSound,
  } = global
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [profiles, setProfiles] = useState()
  const [batches, setBatches] = useState([])
  const [focusBatch, setFocusBatch] = useState()
  const [longitude, setLongitude] = useState()
  const [latitude, setLatitude] = useState()
  const [storeLocations, setStoreLocations] = useState()
  const [lastHeartbeatTime, setLastHeartbeatTime] = useState(0)
  const [lastGetBatchesTime, setLastGetBatchesTime] = useState(0)
  const [lastGetStoresTime, setLastGetStoresTime] = useState(0)
  const [lastReportLocationTime, setLastReportLocationTime] = useState(0)
  const [lastGetLocationTime, setLastGetLocationTime] = useState(0)
  const [lastGetActiveBatchTime, setLastGetActiveBatchTime] = useState(0)
  const [fakeLatitude, setFakeLatitude] = useState()
  const [fakeLongitude, setFakeLongitude] = useState()
  const [useFakeLocation, setUseFakeLocation] = useState(false)
  const [fakeLocationStore, setFakeLocationStore] = useState()
  const [fakeGPSVisible, setFakeGPSVisible] = useState(false)
  const [closedFakeGPS, setClosedFakeGPS] = useState(false)
  const [isGettingProfiles, setIsGettingProfiles] = useState(false)
  const [isPingingHeartbeat, setIsPingingHeartbeat] = useState(false)

  const [errorCountHeartbeat, setErrorCountHeartbeat] = useState(0)
  const [errorCountActiveBatch, setErrorCountActiveBatch] = useState(0)
  const [errorCountReportLocation, setErrorCountReportLocation] = useState(0)
  const [errorCountGetStores, setErrorCountGetStores] = useState(0)
  const [errorCountGetBatches, setErrorCountGetBatches] = useState(0)

  const [startup_pingAlive, setStartup_pingAlive] = useState(false)
  const [startup_geoLocation, setStartup_geoLocation] = useState(false)
  const [startup_getProfiles, setStartup_getProfiles] = useState(false)
  const [startup_getStores, setStartup_getStores] = useState(false)
  const [startup_reportLocation, setStartup_reportLocation] = useState(false)
  const [startup_checkActive, setStartup_checkActive] = useState(false)
  const [startup_getBatches, setStartup_getBatches] = useState(false)

  const handlePingAlive = async () => {
    const res = await pingAlive(sessionId)
    setIsPingingHeartbeat(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Missing account profiles') {
        navigate({
          pathname: '/batchfinder/identity',
          search: searchParams.toString(),
        })
      } else if (res.body === 'Not allowed to go online') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
        alert(
          `Not allowed to go online. Please contact @BatchFinderDev on Telegram.\n\n${accountId}`,
        )
      } else {
        setErrorCountHeartbeat(errorCountHeartbeat + 1)
        if (errorCountHeartbeat > 3) {
          showAlert(
            'Error sending live',
            `Raw Error: ${JSON.stringify(res)}`,
            'client_error',
          )
        }
      }
    } else if (res.statusCode === 200) {
      setErrorCountHeartbeat(0)
      setStartup_pingAlive(true)
    } else if (res.statusCode === 0) {
      setErrorCountHeartbeat(errorCountHeartbeat + 1)
      if (errorCountHeartbeat > 3) {
        showAlert(
          'Error sending live',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else {
      showAlert(
        'Error sending live',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleGetStoreLocations = async () => {
    const res = await getStoreLocations(sessionId, latitude, longitude, 5)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'License not found') {
        await handleGetCurrentLicense()
        navigate({ pathname: '/app', search: searchParams.toString() })
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else {
        setErrorCountGetStores(errorCountGetStores + 1)
        if (errorCountGetStores > 3) {
          showAlert(
            'Error getting store',
            `Raw Error: ${JSON.stringify(res)}`,
            'client_error',
          )
        }
      }
    } else if (res.statusCode === 200) {
      setErrorCountGetStores(0)
      setStoreLocations(res.body.stores)
      setStartup_getStores(true)
    } else if (res.statusCode === 0) {
      setErrorCountGetStores(errorCountGetStores + 1)
      if (errorCountGetStores > 3) {
        showAlert(
          'Error getting store',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else {
      showAlert(
        'Error getting stores',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleReportLocation = async (extraPause = false) => {
    if (extraPause) {
      await new Promise((resolve) => setTimeout(resolve, 2000))
    }
    const lat = useFakeLocation ? fakeLatitude : latitude
    const lon = useFakeLocation ? fakeLongitude : longitude
    const res = await reportLocation(sessionId, lat, lon)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else {
        setErrorCountReportLocation(errorCountReportLocation + 1)
        if (errorCountReportLocation > 3) {
          showAlert(
            'Error updating position',
            `Raw Error: ${JSON.stringify(res)}`,
            'client_error',
          )
        }
      }
    } else if (res.statusCode === 200) {
      setErrorCountReportLocation(0)
      setStartup_reportLocation(true)
    } else if (res.statusCode === 0) {
      setErrorCountReportLocation(errorCountReportLocation + 1)
      if (errorCountReportLocation > 3) {
        showAlert(
          'Error updating position',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else {
      showAlert(
        'Error updating position',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleGetProfiles = async () => {
    const res = await getProfiles(sessionId)
    setIsGettingProfiles(false)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      const enabledProfiles = res.body.profiles.filter(
        (profile) => profile.enabled,
      )
      setProfiles(enabledProfiles)
      setStartup_getProfiles(true)
      if (!enabledProfiles.length) {
        showAlert(
          'No Filters Enabled',
          'This is a warning. You have not created and/or enabled any filters. This means that BatchFinder will NOT automatically accept any batches. If you intend to do this, you can ignore this message. Otherwise, please set up your filters before running the bot.',
          'user_warning',
        )
      }
    } else {
      showAlert(
        'Error retrieving filters',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleGetBatches = async () => {
    const lat = useFakeLocation ? fakeLatitude : latitude
    const lon = useFakeLocation ? fakeLongitude : longitude
    // console.log('Requesting...', new Date().toISOString())
    const res = await getBatches(sessionId, profiles, lat, lon)
    // console.log('Done.        ', new Date().toISOString())
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'License not found') {
        await handleGetCurrentLicense()
        navigate({ pathname: '/app', search: searchParams.toString() })
      } else if (res.body === 'Rate limited') {
        // console.log('Rate limited:', new Date().toISOString())
        setLastGetBatchesTime(new Date().getTime() + 30000)
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else {
        setErrorCountGetBatches(errorCountGetBatches + 1)
        if (errorCountGetBatches > 3) {
          showAlert(
            'Error getting batches',
            `Raw Error: ${JSON.stringify(res)}`,
            'client_error',
          )
        }
      }
    } else if (res.statusCode === 200) {
      setErrorCountGetBatches(0)
      if (res.body.acceptedBatch) {
        acceptedBatchSound.src = '/alert_sound.mp3'
        navigate({
          pathname: '/batchfinder/active',
          search: searchParams.toString(),
        })
      } else {
        // setBatches([
        //   {
        //     earnings: 18,
        //     tip: 11,
        //     distance: 5.4,
        //     customers: 2,
        //     items: 21,
        //     units: 25,
        //     storeBrands: ['ALDI'],
        //     storeCoordinates: [{ longitude: -87.70297, latitude: 42.02642 }],
        //     storeAddresses: ['2211 Oakton St.'],
        //     metadata:
        //       'f86dfa9f54c6f80647c2f61415a88cbc007106585440be8e42f4645e701c1ad8-1666116586-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C20794%7C8827967501231258%7C8827889177132167/accept',
        //   },
        //   {
        //     earnings: 20.97,
        //     tip: 6.44,
        //     distance: 7.3,
        //     customers: 1,
        //     items: 4,
        //     units: 5,
        //     storeBrands: ['Wegmans'],
        //     storeCoordinates: [
        //       { longitude: -71.1756718, latitude: 42.3191343 },
        //     ],
        //     storeAddresses: ['200 Boylston St'],
        //     metadata:
        //       '3e7183c80e31863536a8eb6cbfe803c7a3576dd229b46f6afe49f623c42ecac2-1663164580-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C7395%7C8532722421330316/accept',
        //   },
        //   {
        //     earnings: 31.99,
        //     tip: 10.63,
        //     distance: 7.1,
        //     customers: 2,
        //     items: 22,
        //     units: 23,
        //     storeBrands: ['Costco'],
        //     storeCoordinates: [
        //       { longitude: -71.0715048, latitude: 42.3968886 },
        //     ],
        //     storeAddresses: ['2 Mystic View Road'],
        //     metadata:
        //       'a4f947d842c26a1b2c3ac5e11388fc183350f3dc31772e3da46b13446e14c2a7-1665597984-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C172%7C8776065922133499%7C8774594132935603/accept',
        //   },
        //   {
        //     earnings: 10.33,
        //     tip: 3.33,
        //     distance: 5.3,
        //     customers: 1,
        //     items: 3,
        //     units: 4,
        //     storeBrands: ['Save Mart'],
        //     storeCoordinates: [
        //       { longitude: -119.6825992, latitude: 36.8388969 },
        //     ],
        //     storeAddresses: ['1835 Hearndon Avenue'],
        //     metadata:
        //       '22ce7c6103aa8d419e19fe53f53299322207075acb641d57b6cb1fe0df914871-1662425608-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C34768%7C8458876681330455/accept',
        //   },
        //   {
        //     earnings: 33.23,
        //     tip: 9.78,
        //     distance: 7.7,
        //     customers: 3,
        //     items: 33,
        //     units: 41,
        //     storeBrands: ['Market Basket'],
        //     storeCoordinates: [
        //       { longitude: -71.0429953, latitude: 42.3955262 },
        //     ],
        //     storeAddresses: ['170 EVERETT AVE'],
        //     metadata:
        //       '7a0379c1f0ac7d8a84b8efef3d07905f602b5e0dafabfe14dcc6c3645029b7cb-1668469537-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C2444%7C9062508053835040%7C9062772903832860%7C9062801944133688/accept',
        //   },
        //   {
        //     earnings: 31.88,
        //     tip: 12.48,
        //     distance: 1.9,
        //     customers: 2,
        //     items: 46,
        //     units: 59,
        //     storeBrands: ['Walmart'],
        //     storeCoordinates: [
        //       { longitude: -123.0270661, latitude: 49.2592375 },
        //     ],
        //     storeAddresses: ['3585 GRANDVIEW HIGHWAY'],
        //     metadata:
        //       '5fa1f2b34b2816810ea08f56a71c5b0c406631e88073e18fa9ba91d7b39906b7-1665954612-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C35774%7C8811631018730327%7C8811763363433229/accept',
        //   },
        //   {
        //     earnings: 13.36,
        //     tip: 6.36,
        //     distance: 1.2,
        //     customers: 1,
        //     items: 20,
        //     units: 22,
        //     storeBrands: ['Stop & Shop'],
        //     storeCoordinates: [
        //       { longitude: -70.9589524, latitude: 42.4698052 },
        //     ],
        //     storeAddresses: ['35 Washington Street'],
        //     metadata:
        //       'e4239b87009fb2e7a590652466c593f4c43f1e7b0be72c89eb3b95d0b30d1dbf-1665156946-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C17444%7C8731972931730477/accept',
        //   },
        //   {
        //     earnings: 9.08,
        //     tip: 0,
        //     distance: 7.4,
        //     customers: 1,
        //     items: 15,
        //     units: 19,
        //     storeBrands: ['Safeway'],
        //     storeCoordinates: [{ longitude: -76.947457, latitude: 38.89314 }],
        //     storeAddresses: ['322 40th St. Ne'],
        //     metadata:
        //       '208a97869eb4a46ba73259e1bd6abd14f60a081a55e4c0618db96e7ca1d282d5-1666550185-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C2424%7C8871194274334505/accept',
        //   },
        //   {
        //     earnings: 52.91,
        //     tip: 29.4,
        //     distance: 8.9,
        //     customers: 3,
        //     items: 42,
        //     units: 48,
        //     storeBrands: ['Costco'],
        //     storeCoordinates: [{ longitude: -122.999718, latitude: 49.260635 }],
        //     storeAddresses: ['4500 Still Creek Dr'],
        //     metadata:
        //       'c5fe23324b60b426c333c07ebf52dec2e3d6b24358c39f664bb409a26d951555-1664730457-0',
        //     acceptLink:
        //       'https://shopper-api.instacart.com/driver/virtual_batches/delivery%7Ccar%7C40181%7C8689351723233067%7C8689200807130986%7C8686067607331414/accept',
        //   },
        // ])
        setBatches(res.body.batches)
        setStartup_getBatches(true)
        setLastGetBatchesTime(
          Math.max(new Date().getTime(), lastGetBatchesTime),
        )
      }
    } else if (res.statusCode === 0) {
      setErrorCountGetBatches(errorCountGetBatches + 1)
      if (errorCountGetBatches > 3) {
        showAlert(
          'Error getting batches',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else {
      showAlert(
        'Error getting batches',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleGetActiveBatch = async () => {
    const res = await getActiveBatch(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Rate limited') {
        setLastGetActiveBatchTime(lastGetActiveBatchTime + 60000) //60 second pause
      } else {
        setErrorCountActiveBatch(errorCountActiveBatch + 1)
        if (errorCountActiveBatch > 3) {
          showAlert(
            'Error checking active',
            `Raw Error: ${JSON.stringify(res)}`,
            'client_error',
          )
        }
      }
    } else if (res.statusCode === 200) {
      setErrorCountActiveBatch(0)
      if (res.body.batch) {
        navigate({
          pathname: '/batchfinder/active',
          search: searchParams.toString(),
        })
      } else {
        setStartup_checkActive(true)
      }
    } else if (res.statusCode === 0) {
      setErrorCountActiveBatch(errorCountActiveBatch + 1)
      if (errorCountActiveBatch > 3) {
        showAlert(
          'Error checking active',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else {
      showAlert(
        'Error checking active',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  useEffect(() => {
    if (sessionId && !lastHeartbeatTime && !isPingingHeartbeat) {
      const helper = async () => {
        await handlePingAlive()
        setLastHeartbeatTime(new Date().getTime())
      }
      setIsPingingHeartbeat(true)
      helper()
    }
  })

  useEffect(() => {
    if (profiles === undefined && sessionId && !isGettingProfiles) {
      setIsGettingProfiles(true)
      handleGetProfiles()
    }
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (new Date().getTime() > lastGetLocationTime + 30000) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (!position?.coords?.latitude || !position?.coords?.longitude) {
                alert(
                  'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
                )
                navigate({ pathname: '/app', search: searchParams.toString() })
              } else {
                setLatitude(position.coords.latitude)
                setLongitude(position.coords.longitude)
                setLastGetLocationTime(new Date().getTime())
                setStartup_geoLocation(true)
              }
            },
            (error) => {
              alert(
                'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
              )
              navigate({ pathname: '/app', search: searchParams.toString() })
            },
          )
        } else {
          alert(
            'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
          )
          navigate({ pathname: '/app', search: searchParams.toString() })
        }
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (sessionId && new Date().getTime() > lastHeartbeatTime + 30000) {
        setLastHeartbeatTime(new Date().getTime())
        handlePingAlive()
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        lastHeartbeatTime &&
        latitude &&
        longitude &&
        new Date().getTime() > lastGetStoresTime + 60000
      ) {
        setLastGetStoresTime(new Date().getTime())
        handleGetStoreLocations()
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        lastHeartbeatTime &&
        ((!useFakeLocation && latitude && longitude) ||
          (useFakeLocation && fakeLatitude && fakeLongitude)) &&
        new Date().getTime() > lastReportLocationTime + 60000
      ) {
        setLastReportLocationTime(new Date().getTime())
        handleReportLocation()
      } else if (
        sessionId &&
        lastReportLocationTime !== 0 &&
        new Date().getTime() > lastReportLocationTime + 120000
      ) {
        showAlert(
          'Loc Handler Inactive',
          `Please send a screenshot of this message to @BatchFinderDev on Telegram.`,
          'client_error',
        )
      }
    }, 1500)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        lastHeartbeatTime &&
        profiles !== undefined &&
        latitude &&
        longitude &&
        new Date().getTime() > lastGetBatchesTime + 5500
      ) {
        setLastGetBatchesTime(new Date().getTime())
        handleGetBatches()
      }
    }, 250)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        lastHeartbeatTime &&
        new Date().getTime() > lastGetActiveBatchTime + 120000
      ) {
        setLastGetActiveBatchTime(new Date().getTime())
        handleGetActiveBatch()
      }
    }, 2000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    if (closedFakeGPS) {
      setClosedFakeGPS(false)
      setStartup_reportLocation(false)
      handleReportLocation(true)
    }
  }, [closedFakeGPS])

  return (
    <div class="noselect">
      <BatchFinderStartupDialog
        pingAlive={startup_pingAlive}
        getProfiles={startup_getProfiles}
        geoLocation={startup_geoLocation}
        getStores={startup_getStores}
        checkActive={startup_checkActive}
        reportLocation={startup_reportLocation}
        getBatches={startup_getBatches}
      />
      <BatchFinderFocusBatch
        batch={focusBatch}
        setBatch={setFocusBatch}
        sessionId={sessionId}
        showAlert={showAlert}
      />
      <BatchFinderFakeGPS
        visible={fakeGPSVisible}
        hideFakeGPS={() => setFakeGPSVisible(false)}
        storeLocations={storeLocations}
        fakeLocationStore={fakeLocationStore}
        useFakeLocation={useFakeLocation}
        userLatitude={latitude}
        userLongitude={longitude}
        setUseFakeLocation={setUseFakeLocation}
        setFakeLatitude={setFakeLatitude}
        setFakeLongitude={setFakeLongitude}
        fakeLatitude={fakeLatitude}
        fakeLongitude={fakeLongitude}
        setFakeLocationStore={setFakeLocationStore}
        setClosedFakeGPS={setClosedFakeGPS}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            paddingTop: '20px',
          }}
        >
          BatchFinder App
        </h1>
        <p
          style={{
            textAlign: 'center',
            color: 'red',
            fontSize: '14px',
            marginTop: '10px',
            marginBottom: '5px',
            fontWeight: 500,
          }}
        >
          Keep this screen open for BatchFinder to run.
        </p>
        <NeuDiv
          style={{
            width: '95vw',
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
          color={colors.offWhite}
          distance={2}
          revert
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                fontWeight: 500,
                fontSize: '20px',
                color: colors.black,
              }}
            >
              Available Batches: {batches?.length}
            </p>
            <ProgressCircular
              indeterminate
              size={24}
              width={2}
              color="var(--primary)"
              style={{
                marginLeft: '20px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            />
          </div>

          {batches.map((item) => (
            <BatchListItem
              key={item.acceptLink}
              batch={item}
              onClick={() => setFocusBatch(item)}
            />
          ))}
        </NeuDiv>
        {storeLocations && (
          <Button
            variant="contained"
            color={useFakeLocation ? 'success' : 'primary'}
            sx={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              fontSize: '12px',
            }}
            onClick={() => setFakeGPSVisible(true)}
          >
            {useFakeLocation ? 'Fake' : 'Set'} Location
          </Button>
        )}
      </div>
      <BackButton />
    </div>
  )
}
