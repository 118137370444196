import { NeuButton } from 'neumorphism-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../../colors'
import { ProgressCircular } from 'ui-neumorphism'

const getTextStyle = (glowText, turboText) => {
  if (glowText)
    return {
      background: '#FF0000',
      background:
        'linear-gradient(to right, #FF0000 0%, #FF8E4D 90%, #FFAD33 100%)',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      fontWeight: 800,
    }

  if (turboText)
    return {
      background: 'linear-gradient(135deg, #8b18ff, #2a5fff)',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      fontWeight: 800,
    }

  return {}
}

export const BatchFinderRouteLink = ({
  label,
  to,
  icon,
  fontSize,
  disabled,
  subLabel,
  glowText,
  turboText,
  isRunning,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <div
      class="noselect"
      style={{
        display: 'block',
        marginBottom: '25px',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <NeuButton
        style={{
          width: '70vw',
          height: '50px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: colors.black,
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
          textDecoration: disabled ? 'line-through' : 'none',
        }}
        color={colors.offWhite}
        distance={2}
        onClick={() => {
          navigate({ pathname: to, search: searchParams.toString() })
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '0px 15px',
            alignItems: 'center',
          }}
        >
          {icon}
          <div
            style={{
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: colors.black,
                fontSize: !!fontSize ? fontSize : 'auto',
                ...getTextStyle(glowText, turboText),
              }}
            >
              {label}
            </p>
            {!!subLabel && (
              <p
                style={{
                  color: colors.gray300,
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {subLabel}
              </p>
            )}
          </div>
          {isRunning && (
            <ProgressCircular
              indeterminate
              size={22}
              width={3}
              color="var(--primary)"
              style={{
                position: 'absolute',
                top: '10px',
                right: '7px',
              }}
            />
          )}
        </div>
      </NeuButton>
    </div>
  )
}
