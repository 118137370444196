import { Card, Dialog } from 'ui-neumorphism'
import { colors } from '../colors'
import { Button } from '@mui/material'

export const AlertDialog = ({
  visible,
  hideAlertDialog,
  alertTitle,
  alertType,
  alertDescription,
}) => {
  return (
    <Dialog visible={visible} onClose={hideAlertDialog}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
          width: '90vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: '22px',
                color: colors.black,
                paddingBottom: '15px',
                fontWeight: 500,
              }}
            >
              {alertTitle}
            </p>
            <p
              style={{
                textAlign: 'left',
                fontSize: '18px',
                color: colors.black,
                whiteSpace: 'pre-line',
              }}
            >
              {alertDescription}
            </p>
          </div>
          <Button
            variant="contained"
            sx={{ marginTop: '20px' }}
            onClick={hideAlertDialog}
          >
            CLOSE
          </Button>
          <p
            style={{
              textAlign: 'flex-start',
              fontSize: '14px',
              color: colors.black,
              marginTop: '20px',
            }}
          >
            Questions? Contact{' '}
            <a
              href="https://t.me/BatchFinderDev"
              target="_blank"
              style={{ fontWeight: 600 }}
            >
              @BatchFinderDev
            </a>{' '}
            on Telegram
          </p>
        </div>
      </Card>
    </Dialog>
  )
}
