import PaypalTemplate from '../PaypalTemplate'

export const PurchaseTurboSevenDay = ({
  config,
  isLoggedIn,
  saveSessionId,
  sessionId,
  setCurrentLicense,
  global,
  salePrice,
}) => {
  if (!config) return
  return (
    <PaypalTemplate
      isLoggedIn={isLoggedIn}
      productDescription={'7 Day Turbo License'}
      category={'7 Day Turbo'}
      // price={config.value}
      price={salePrice}
      sessionId={sessionId}
      saveSessionId={saveSessionId}
      setCurrentLicense={setCurrentLicense}
      global={global}
      preSalePrice={config.value}
      isSale={true}
    />
  )
}
