import axios from 'axios'
import { CLIENT_API_KEY } from '../access_keys'

export const checkIsLoggedIn = async (session_id) => {
  const res = {
    isLoggedIn: false,
    error: false,
    accountId: undefined,
    errorRaw: '',
  }
  const config = {
    method: 'POST',
    url: 'https://owworarpe5.execute-api.us-west-1.amazonaws.com/Prod/avalon/account/isloggedin',
    headers: {
      apikey: CLIENT_API_KEY,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ session_id }),
  }
  try {
    const response = await axios(config)
    res.isLoggedIn = true
    res.error = false
    res.accountId = response.data.account_id
  } catch (error) {
    if (
      error.response.data === 'Invalid session' ||
      error.response.data === 'Malformed request' ||
      error.response.data === 'Invalid Authorization'
    ) {
      res.isLoggedIn = false
    } else {
      res.isLoggedIn = undefined //Indetermined
    }
    res.error = true
    res.errorRaw = error
  }
  return res
}
