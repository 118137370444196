import { colors } from '../../colors'
import { ReactComponent as Arrow } from './arrow.svg'

export const Onboarding_2 = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.splashDark,
        color: colors.white,
        padding: '50px 20px 20px 20px',
        textAlign: 'center',
      }}
    >
      <h1
        style={{ fontSize: '1.8rem', fontWeight: 500, padding: '0 0 2rem 0' }}
        className="slide-in"
      >
        How BatchFinder Works
      </h1>
      <div
        style={{
          width: '90%',
          backgroundColor: '#404040',
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
        }}
        className="slide-in"
      >
        <p>Sign out of the Instacart app</p>
        <Arrow style={{ margin: '1.5rem' }} />
        <p>Start running BatchFinder</p>
        <Arrow style={{ margin: '1.5rem' }} />
        <p>BatchFinder scans all available batches</p>
        <Arrow style={{ margin: '1.5rem' }} />
        <p>
          If a batch fits one of your filters, BatchFinder will{' '}
          <b style={{ color: colors.splashGreen }}>automatically</b> accept it
        </p>
        <Arrow style={{ margin: '1.5rem' }} />
        <p>Transfer the batch to the Instacart app</p>
      </div>
    </div>
  )
}
