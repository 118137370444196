import { NeuButton } from 'neumorphism-react'
import { colors } from '../../../colors'

export const ProfileListItem = ({
  profile,
  setFocusProfile,
  setIsAddressWhitelist,
  setOriginalFocusProfile,
  setOrderedStoreAddresses,
}) => {
  return (
    <NeuButton
      style={{
        width: '80vw',
        height: '50px',
        minHeight: '50px',
        marginTop: '0px',
        marginTop: '0px',
        textTransform: 'none',
        fontWeight: 'bold',
        color: colors.black,
      }}
      color={colors.offWhite}
      distance={2}
      onClick={() => {
        setOriginalFocusProfile(JSON.parse(JSON.stringify(profile)))
        setFocusProfile(profile)
        setIsAddressWhitelist(
          (!profile.store_address_blacklist.length &&
            !profile.store_address_whitelist.length) ||
            !!profile.store_address_whitelist.length,
        )
        setOrderedStoreAddresses(profile)
      }}
    >
      {profile.name}
    </NeuButton>
  )
}
