import { colors } from '../../colors'

export const Onboarding_4 = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.splashDark,
        color: colors.white,
        padding: '50px 20px 20px 20px',
        textAlign: 'center',
      }}
    >
      <h1
        style={{ fontSize: '1.8rem', fontWeight: 500, padding: '0 0 2rem 0' }}
        className="slide-in"
      >
        Don't Be Too Specific!
      </h1>
      <div
        style={{
          width: '90%',
          backgroundColor: '#404040',
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
        }}
        className="slide-in"
      >
        <p>Make sure you aren't too picky with your filters!</p>
        <b style={{ padding: '0.75rem 0 0.5rem 0', fontSize: '1.2rem' }}>
          Example #1
        </b>
        <div
          style={{
            backgroundColor: colors.splashDark,
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <p>Filter</p>
          <p style={{ color: colors.red }}>Minimum $100 Tip</p>
        </div>
        <p style={{ padding: '1rem 0' }}>
          Batches with $100+ tips are too rare!
        </p>
        <b style={{ padding: '0.5rem', fontSize: '1.2rem' }}>Example #2</b>
        <div
          style={{
            backgroundColor: colors.splashDark,
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <p>Filter</p>
          <p style={{ color: colors.red }}>Minimum $40 earnings</p>
          <p style={{ color: colors.red }}>Max 5 Items</p>
        </div>
        <p style={{ padding: '1rem 0' }}>
          Batches with $40+ earnings, <b>but only 5 items</b>, are too rare!
        </p>
        <p style={{ padding: '0.75rem 0 0 0' }}>
          <b style={{ color: colors.splashGreen }}>Remember:</b> BatchFinder can
          only accept batches that exist.
          <br />
          <span style={{ color: colors.red, fontWeight: 500 }}>
            If your filters are too strict, BatchFinder won't accept anything.
          </span>
        </p>
      </div>
    </div>
  )
}
