export const BatchFinderSetupVideoPage = () => {
  return (
    <div class="noselect">
      {' '}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <iframe
            style={{ height: '100vh' }}
            src="https://www.youtube.com/embed/VTw7hZrhvrs?autoplay=1"
          />
          {/* <video controls autoPlay style={{ height: '100vh' }}>
            <source
              src="/Videos/Avalon_BatchFinder_Setup.mp4"
              type="video/mp4"
            />
          </video> */}
        </div>
      </div>
    </div>
  )
}
