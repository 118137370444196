import axios from 'axios'
import { CLIENT_API_KEY } from '../access_keys'

export const uploadPlatformLogin = async (session_id, platform, login_data) => {
  const res = {
    body: '',
    statusCode: 0,
  }
  const config = {
    method: 'POST',
    url: 'https://owworarpe5.execute-api.us-west-1.amazonaws.com/Prod/avalon/platformlogin/upload',
    headers: {
      apikey: CLIENT_API_KEY,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      session_id,
      platform,
      login_data,
    }),
  }
  try {
    const response = await axios(config)
    res.statusCode = 200
    res.body = response.data
    return res
  } catch (error) {
    res.statusCode = error.response.status
    res.body = error.response.data
    return res
  }
}
