import React, { useState, useRef, useEffect } from 'react'

export const FadeUp = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Only consider the element as "in view" when it's fully visible
          if (entry.intersectionRatio >= 0.3) {
            setIsVisible(true)
            observer.disconnect()
          }
        })
      },
      {
        threshold: 0.3, // This means 100% of the target element is visible
      },
    )

    observer.observe(textRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div
      ref={textRef}
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(20px)', // adjust the pixel value as needed
        transition: 'opacity 1s, transform 1s',
      }}
    >
      {props.children}
    </div>
  )
}
