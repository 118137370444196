import { fontWeight } from '@mui/system'
import { colors } from '../../../colors'

export const BatchFinderHowFiltersWork = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        color: colors.black,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          In a <b>Filter Profile</b>, you can specify your batch preferences
          such as Minimum Earnings and Maximum Distance
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          You can create multiple Filter Profiles if you want to accept
          different types of batches.
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          BatchFinder uses these Filter Profiles to look for batches that you
          want
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '10px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          If BatchFinder finds a batch that fits{' '}
          <b>all of your criteria in one filter</b>, it will be automatically
          accepted
        </p>
      </div>
      <p
        style={{
          textAlign: 'center',
          fontSize: '1.5em',
          fontWeight: 500,
          paddingBottom: '15px',
        }}
      >
        Example
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          You create a Filter Profile with <b>Minimum Earnings: $30</b> and{' '}
          <b>Max Items: 40</b>
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          A batch with <b style={{ color: colors.green }}>$44 earnings</b> and{' '}
          <b style={{ color: colors.green }}>28 items</b>{' '}
          <b style={{ color: colors.green }}>Would be accepted</b>
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          A batch with{' '}
          <b style={{ color: colors.doordash_red }}>$18 earnings</b> and{' '}
          <b style={{ color: colors.green }}>12 items</b>{' '}
          <b style={{ color: colors.doordash_red }}>Would not be accepted</b>
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          A batch with <b style={{ color: colors.green }}>$55 earnings</b> and{' '}
          <b style={{ color: colors.doordash_red }}>72 items</b>{' '}
          <b style={{ color: colors.doordash_red }}>Would not be accepted</b>
        </p>
      </div>
    </div>
  )
}
