import { colors } from '../../../colors'

export const BatchFinderWhatIsIt = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        color: colors.black,
      }}
    >
      <p
        style={{
          color: colors.black,
          fontSize: '1.1em',
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        BatchFinder is an Instacart Shopper bot
      </p>
      <p
        style={{
          fontSize: '14px',
          textAlign: 'center',
          padding: '5px',
        }}
      >
        It will accept high paying and convenient batches
      </p>
      <p
        style={{
          fontSize: '1.6em',
          fontWeight: 500,
          padding: '20px 0px 15px 0px',
          textAlign: 'center',
        }}
      >
        Features
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          <b>Automatic: </b> BatchFinder accepts <b>high quality</b> batches for
          you before other users see them
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          <b>Customizable: </b>Choose what batches you want to accept using
          filters like <b>Minimum Earnings</b> and <b>Max Distance</b> and more
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '2em',
          }}
        >
          &#8226;&nbsp;
        </p>
        <p style={{ fontSize: '1.1em' }}>
          <b>Convenient: </b> Choose a location where you want BatchFinder to
          search for batches so you can stay home while looking for a batch
        </p>
      </div>
    </div>
  )
}
