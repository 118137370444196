import { ListItem, ListItemGroup } from 'ui-neumorphism'

export const Account = () => {
  return (
    <div
      class="noselect"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <h1
        style={{
          fontSize: '24px',
        }}
      >
        Account
      </h1>
      <ListItemGroup
        link
        value={this.state.active}
        onChange={({ active }) => this.setState({ active })}
      >
        <ListItem title={'hello'} />
        <ListItem title={'hello again'} />
        <ListItem title={'hello again friend'} />
        <ListItem active title={'hello again oh hello'} />
      </ListItemGroup>
    </div>
  )
}
