import { CircularProgress, circularProgressClasses } from '@mui/material'
import { Box } from '@mui/system'
import { colors } from '../colors'

export const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        zIndex: 1,
        backgroundColor: colors.loading,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          position: 'absolute',
          left: '45vw',
          top: '45vh',
        }}
        size={40}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          position: 'absolute',
          left: '45vw',
          top: '45vh',
        }}
        size={40}
        thickness={4}
      />
    </Box>
  )
}
