import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../colors'
import { Onboarding_1 } from './Onboarding_1'
import { Onboarding_2 } from './Onboarding_2'
import { Onboarding_3 } from './Onboarding_3'
import { Onboarding_4 } from './Onboarding_4'
import { Onboarding_5 } from './Onboarding_5'
import { OnboardingFinal } from './OnboardingFinal'
import './onboarding.css'
import { LoadingSpinner } from '../../components/LoadingSpinner'

export const Onboarding = ({ handlePutUserViews, userViews }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isLoading, _isLoading] = useState(false)
  const [pageIdx, _pageIdx] = useState(0)

  const handleFinishOnboarding = async () => {
    _isLoading(true)
    await handlePutUserViews({ finished_onboarding: true })
    _isLoading(false)
    navigate({ pathname: '/app', search: searchParams.toString() })
  }

  const Pages = [
    <Onboarding_1 />,
    <Onboarding_2 />,
    <Onboarding_3 />,
    <Onboarding_4 />,
    <Onboarding_5 />,
    <OnboardingFinal handleGetStarted={handleFinishOnboarding} />,
  ]

  useEffect(() => {
    if (userViews?.finished_onboarding === true) {
      navigate({ pathname: '/app', search: searchParams.toString() })
    }
  }, [userViews])

  const handleNextClick = () => {
    _pageIdx(Math.min(Pages.length - 1, pageIdx + 1))
  }

  return (
    <div class="noselect">
      {isLoading && <LoadingSpinner />}
      {Pages[pageIdx]}
      {pageIdx !== 0 && (
        <p
          style={{
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            textDecoration: 'underline',
            color: colors.white,
          }}
          onTouchStart={(e) => {
            e.currentTarget.style.transform = 'scale(0.95)'
          }}
          onTouchEnd={(e) => {
            e.currentTarget.style.transform = 'scale(1)'
          }}
          onClick={() => {
            _pageIdx(Math.max(0, pageIdx - 1))
          }}
        >
          Back
        </p>
      )}
      {pageIdx < Pages.length - 1 && (
        <div
          style={{
            position: 'absolute',
            zIndex: 10,
            bottom: '20px',
            right: '20px',
            backgroundColor: colors.white,
            color: colors.black,
            padding: '8px 15px',
            borderRadius: '10px',
            transition: 'transform 0.1s, filter 0.1s',
            cursor: 'pointer',
          }}
          onTouchStart={(e) => {
            e.currentTarget.style.transform = 'scale(0.95)'
          }}
          onTouchEnd={(e) => {
            e.currentTarget.style.transform = 'scale(1)'
          }}
          onClick={handleNextClick}
        >
          <h2>Next</h2>
        </div>
      )}
      {pageIdx === Pages.length - 1 && (
        <div
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            width: '10.5rem',
            height: '3rem',
            minHeight: '3rem',
            fontSize: '1rem',
            fontWeight: 800,
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: colors.splashGreen,
            color: colors.splashDark,
            border: 'none',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            transition: 'transform 0.1s, filter 0.1s',
            textDecoration: 'none',
          }}
          onTouchStart={(e) => {
            e.currentTarget.style.transform = 'scale(0.95)'
          }}
          onTouchEnd={(e) => {
            e.currentTarget.style.transform = 'scale(1)'
          }}
          onClick={handleFinishOnboarding}
        >
          Get Started
        </div>
      )}
    </div>
  )
}
