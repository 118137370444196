import { colors } from '../../../colors'
import './index.css'

export const Basic = ({ onClick }) => {
  return (
    <div
      className="basic"
      onClick={onClick}
      style={{
        padding: '15px 20px',
        borderRadius: '12px',
        cursor: 'pointer',
        backgroundColor: colors.offWhite,
      }}
    >
      <h3 style={{ color: colors.black }}>Basic</h3>
      <p style={{ color: 'black', fontSize: '0.9rem' }}>
        Includes all standard functions
      </p>
    </div>
  )
}
