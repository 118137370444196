import { colors } from '../../colors'

export const Header = () => {
  return (
    <div
      style={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.splashDark,
        color: '#fff',
        fontSize: '24px',
        padding: '10px 0px 15px 0px',
        position: 'fixed',
        top: 0,
        zIndex: 1000,
      }}
    >
      <h1 style={{ textAlign: 'center', fontSize: '24px', fontWeight: 600 }}>
        The Avalon Project
      </h1>
    </div>
  )
}
