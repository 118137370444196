import axios from 'axios'
import { CLIENT_API_KEY } from '../access_keys'

export const assignPaidLicense = async (order_id, session_id) => {
  const res = {
    body: '',
    statusCode: 0,
  }
  const config = {
    method: 'POST',
    url: 'https://owworarpe5.execute-api.us-west-1.amazonaws.com/Prod/avalon/license/assign',
    headers: {
      apikey: CLIENT_API_KEY,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ order_id, session_id, license_type: 'paid' }),
  }
  try {
    const response = await axios(config)
    res.statusCode = 200
    res.body = response.data
    return res
  } catch (error) {
    res.statusCode = error.response.status
    res.body = error.response.data
    return res
  }
}
