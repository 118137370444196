import React from 'react'
import './blog-styles.css'
import { Helmet } from 'react-helmet'
import { CTAButton } from './CTAButton'

const SectionContent = ({ sections }) => {
  return (
    <section>
      {sections.map((section) => (
        <>
          <h2 className="blog-h2">{section.title}</h2>
          <p className="blog-p">{section.body}</p>
        </>
      ))}
    </section>
  )
}

export const BlogTemplate = ({
  title,
  description,
  keywords,
  publishDateString,
  sections,
  urlPath,
}) => {
  return (
    <div className="blog-post">
      <Helmet>
        <title>{title} | BatchFinder</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content="BatchFinder" />
        <link rel="canonical" href={`https://avalon.rip/blog/${urlPath}`} />
      </Helmet>

      <article>
        <header>
          <h1 className="blog-h1">{title}</h1>
          <p className="blog-p">
            Written by BatchFinder on {publishDateString}
          </p>
        </header>

        <SectionContent sections={sections} />

        <footer>
          <p className="blog-footer-p">Thanks for reading!</p>
        </footer>
      </article>
      <CTAButton>Try BatchFinder Today!</CTAButton>
    </div>
  )
}
