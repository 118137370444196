import { colors } from '../colors'
import VerifiedRounded from '@mui/icons-material/VerifiedRounded'
import { IconButton } from 'ui-neumorphism'

export const CurrentLicense = ({ currentLicense }) => {
  const getRemainingTime = (license) => {
    if (!license.expires) {
      return 'Lifetime License, thank you!'
    }
    const remainingDays = Math.floor(license.remaining_time / 86400)
    const remainingHours = Math.floor((license.remaining_time % 86400) / 3600)
    return `Remaining time: ${remainingDays} days, ${remainingHours} hours`
  }

  const getLicenseVariant = (license) => {
    if (license.license_variant === 'turbo') {
      return 'Turbo'
    } else if (license.license_variant === 'premium') {
      return 'Premium'
    } else if (license.license_variant === 'standard') {
      return 'Basic'
    } else {
      return 'Unknown'
    }
  }

  return (
    <div
      style={{
        width: '80vw',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 15px 15px 15px',
        backgroundColor: '#FBFAF8',
        borderRadius: '15px',
        marginTop: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h3
          style={{
            fontSize: '14px',
            fontColor: colors.gray300,
            fontWeight: 500,
          }}
        >
          Current License
        </h3>
        {currentLicense.license_type === 'paid' ? (
          <IconButton rounded text={false}>
            <VerifiedRounded style={{ color: colors.blueLight }} />
          </IconButton>
        ) : undefined}
      </div>

      <h2
        style={{
          color:
            currentLicense.license_type === 'paid'
              ? colors.blueLight
              : colors.yellow,
          marginBottom: '0.8rem',
        }}
      >
        {currentLicense.license_type === 'paid'
          ? 'Paid License'
          : 'Trial License'}
      </h2>
      <p
        style={{
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        {getRemainingTime(currentLicense)}
      </p>
      <p
        style={{
          color: colors.black,
          fontSize: '14px',
          fontWeight: 500,
          marginTop: '2px',
        }}
      >
        {getLicenseVariant(currentLicense)} License
      </p>
      {currentLicense.license_type === 'paid' && (
        <p
          style={{
            fontSize: '14px',
            color: colors.black,
            marginTop: '10px',
            fontWeight: 500,
          }}
        >
          Thank you for supporting the project!
        </p>
      )}
    </div>
  )
}
