import { colors } from '../../../colors'

export const LicenseHeader = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ color: 'white', fontWeight: 500 }}>Get the Best Batches</h1>
      <h1 style={{ color: colors.splashGreen, fontWeight: 800 }}>
        Every Time.
      </h1>
    </div>
  )
}
