import { Link, useLocation } from 'react-router-dom'
import { colors } from '../../colors'

const BlogLink = ({ link, children }) => {
  const location = useLocation()

  return (
    <Link
      to={`/blog/${link}${location.search}`}
      style={{
        backgroundColor: '#3498db',
        border: 'none',
        borderRadius: '4px',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        marginBottom: '20px',
        textDecoration: 'none',
      }}
    >
      {children}
    </Link>
  )
}

export const BlogHome = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: colors.splashDark,
      }}
    >
      <h1 style={{ padding: '50px 0px', color: colors.white }}>
        The Avalon Project Blog
      </h1>
      <BlogLink link="what_are_instacart_bots">
        What is an Instacart Bot?
      </BlogLink>
      <BlogLink link="instacart_bot_download_2023">
        Instacart Bot Download 2023
      </BlogLink>
      <BlogLink link="instacart_batch_grabber">
        Instacart Batch Grabber
      </BlogLink>
      <BlogLink link="instacart_bot_download_2022">
        Instacart Bot Download 2022
      </BlogLink>
    </div>
  )
}
